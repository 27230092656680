import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider } from '@maxi-innovation/ui-kit-frontend';
import 'intersection-observer';

import { ROUTER_PATH } from './constant';

import Store from './store';
import App from './modules/App';
import LikePostContextProvider from 'contexts/LikePostContextProvider';
import './index.scss';

import BrowserDetect from './plugins/browserDetect';

const root = ReactDOM.createRoot(document.getElementById('root'));

const RouteAdapter = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const adaptedHistory = React.useMemo(
		() => ({
			replace(location) {
				navigate(location, { replace: true, state: location.state });
			},
			push(location) {
				navigate(location, { replace: false, state: location.state });
			},
		}),
		[navigate]
	);
	return children({ history: adaptedHistory, location });
};

BrowserDetect.init();
//@ts-ignore
if (BrowserDetect.browser !== 'Explorer') {
	root.render(
		<Provider store={Store}>
			<BrowserRouter
				basename={ROUTER_PATH}
				future={{
					v7_startTransition: true,
					v7_relativeSplatPath: true,
				}}
			>
				<QueryParamProvider ReactRouterRoute={RouteAdapter}>
					<ThemeProvider>
						<LikePostContextProvider>
							<App />
						</LikePostContextProvider>
					</ThemeProvider>
				</QueryParamProvider>
			</BrowserRouter>
		</Provider>
	);
} else {
	//@ts-ignore
	let node = document.getElementById('browser-detect').cloneNode(true);
	let container = document.getElementById('root');
	//@ts-ignore
	container.appendChild(node);
}
