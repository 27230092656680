export default function register() {
	window.addEventListener('load', () => {
		const swUrl = `/sw.js`;
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register(swUrl, {
					scope: './',
				})
				.then(function (registration) {
					return registration;
				});
		}
	});
}

export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then(registration => {
			registration.unregister();
		});
	}
}
