import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Star } from 'react-feather';

import { NotificationHelper } from 'action/notificationHelper';

import { MoreBtn } from '../../atoms';

import {
	handleDragStart,
	handleDragEnter,
	handleDragOver,
	handleDragLeave,
	handleDragEnd,
	handlerDrop,
} from '../../organisms/articlesList/dnd';

import { updateFavoriteState } from 'rest/favorites';

import './style.scss';

export const ArticlesItem = ({
	parentId,
	location,
	canEdit,
	index,
	id,
	wiki,
	shortContent,
	favorite,
	categoryIdBreadCrumb,
	publishLastDateUpdate,
	changeFavoritesIdsList,
}) => {
	const [isFavorite, setIsFavorite] = useState(false);

	const dispatch = useDispatch();

	const changeIsFavoriteStatus = async e => {
		e.preventDefault();
		const id = +e.currentTarget.dataset.id;
		const res = await updateFavoriteState({
			type: 'WIKI_GUIDE',
			objectId: id,
			favorite: !isFavorite,
		});
		if (res.data.responseCode === 0) {
			setIsFavorite(!isFavorite);
			dispatch(
				NotificationHelper(
					isFavorite ? 'Удалено из Избранного' : 'Добавлено в Избранное',
					0
				)
			);
			if (changeFavoritesIdsList) {
				if (isFavorite) {
					changeFavoritesIdsList(id, 'remove');
				} else {
					changeFavoritesIdsList(id, 'add');
				}
			}
		} else {
			dispatch(NotificationHelper(res.data.errorMessage, 1));
		}
	};

	useEffect(() => {
		setIsFavorite(favorite);
	}, [setIsFavorite, favorite]);

	return (
		<div
			className="articles-list__item-wrapper"
			draggable={canEdit}
			data-index={index}
			onDragStart={handleDragStart}
			onDragEnter={handleDragEnter}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDragEnd={handleDragEnd}
			onDrop={handlerDrop}
			key={id}
		>
			<div className="articles-list__item">
				<div className="item__header">
					<Star
						className={isFavorite ? 'item__header-svg active' : 'item__header-svg'}
						data-id={id}
						onClick={changeIsFavoriteStatus}
					/>

					<Link
						to={{
							pathname: `/knowledge/guide/${
								categoryIdBreadCrumb
									? categoryIdBreadCrumb.split('/').at(-1)
									: parentId
							}/article/${id}`,
							state: {
								...location.state,
								back: -1,
							},
						}}
						className="item__header-title"
					>
						{`${wiki.name}`}
					</Link>
				</div>

				<p className="item__author">{`${wiki.lastUpdateUser.surname} ${wiki.lastUpdateUser.name}`}</p>

				<p
					className="item__description"
					dangerouslySetInnerHTML={{ __html: shortContent }}
				></p>

				<div className="item__footer">
					<div className="item__footer-data">
						<span className="item__footer-date">{`${
							publishLastDateUpdate ? publishLastDateUpdate : wiki.lastUpdateDate
						}`}</span>
					</div>

					<MoreBtn
						link={`/knowledge/guide${
							categoryIdBreadCrumb ? categoryIdBreadCrumb : '/' + parentId
						}/article/${id}`}
					/>
				</div>
			</div>
		</div>
	);
};
