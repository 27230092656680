import React from 'react';

export const Bold = () => {
	return (
		<svg
			width="168.1"
			height="202"
			viewBox="0 0 168.1 202"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M131 94.8c18.3-8.1 28.7-23.4 28.7-42.8 0-17.3-7-31.2-20.1-40.3-10.9-7.5-26.1-11.7-42.8-11.7h-96.8v34h17v134h-17v34h90.9c14.8 0 36-2.2 52.7-12.9 16.2-10.4 24.5-26.2 24.5-46.9 0-23.9-14-41.4-37.1-47.4zm-44.8-14.8h-29.2v-46h26.1c26 0 36.6 6.3 36.6 21.9-.1 19.9-18.3 24.1-33.5 24.1zm-29.2 35h30.8c28.5 0 40.2 7.4 40.2 25.7 0 18.1-14 27.3-41.6 27.3h-29.4v-53z" />
		</svg>
	);
};
