import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import { URL_AUTH } from 'constant';
import { api } from 'rest/api';

import { destroyCookie, parseCookies } from 'nookies';

export const useLogOut = () => {
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { accessToken } = parseCookies();

	const logOut = useCallback(async () => {
		setLoading(true);
		try {
			const response = await api.post(
				'/auth/clear',
				{ accessToken: accessToken },
				{
					baseURL: URL_AUTH,
				}
			);
			const { data } = response;
			if (data.responseCode === 0) {
				destroyCookie(undefined, 'accessToken', { path: '/' });
				navigate('/login');
			} else {
				console.error('Auth logOut error', data.errorMessage);
				setLoading(false);
			}
		} catch (error) {
			console.error('Auth logOut error', error);
		}
	}, [accessToken, navigate]);

	return {
		isLoading,
		logOut,
	};
};
