import { REPAIR_SERVICE_SET_CATEGORIES } from 'constant';
import { IRepairCategoryData } from 'entities/repairServices/types';

interface IAction {
	type: string;
	payload: {
		categories: IRepairCategoryData[];
	};
}

export default function repairService(state = [], action: IAction) {
	switch (action.type) {
		case REPAIR_SERVICE_SET_CATEGORIES:
			return {
				...state,
				categories: action.payload,
			};

		default:
			return state;
	}
}
