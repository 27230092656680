import React, { useState } from 'react';
import axios from 'axios';

import { URL } from 'constant';
import { getAccessToken } from 'plugins/token';

export const CoverUploader = props => {
	const [name, setName] = useState(null);
	const [isUpload, setUpload] = useState(false);
	const [isUploadEroror, setError] = useState(false);
	const [uploadProgress, setProgress] = useState(0);

	const api = axios.create({
		baseURL: URL,
		crossDomain: true,
		mode: 'cors',
		withCredentials: true,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAccessToken()}`,
		},
		onUploadProgress: progressEvent => {
			const { loaded, total } = progressEvent;
			onProgress(loaded, total);
		},
	});

	const onChange = e => {
		let file = e.currentTarget.files[0];
		if (file) {
			let reader = new FileReader();
			reader.onload = () => {
				const base64 = reader.result.slice(reader.result.indexOf(',') + 1);
				const dataImage = {
					base64,
					name: file.name,
				};
				onUpload(dataImage);
			};
			reader.readAsDataURL(file);
		}
	};

	const onUpload = data => {
		onLoadStart();
		api.post('/file/save', {
			originalFileName: data.name,
			fileType: 'ARTICLE_PREVIEW',
			base64File: data.base64,
		})
			.then(response => {
				if (response.data.responseCode === 0) {
					onSuccess({
						name: data.name,
						...response.data.response,
					});
				} else {
					onError(response.data.response);
				}
				onLoadEnd();
				setProgress(0);
			})
			.catch(error => {
				onLoadEnd();
				onError(error);
			});
	};

	const onSuccess = data => {
		const { setCoverId, setFilePath } = props;
		setName(data.name);
		setCoverId(data.id);
		if (setFilePath) {
			setFilePath(data.filePath);
		}
	};

	const onError = error => {
		setError(true);
	};

	const onLoadStart = () => {
		setUpload(true);
		setError(false);
	};

	const onLoadEnd = () => {
		setUpload(false);
		document.getElementById('article-cover').value = '';
	};

	const onProgress = (loaded, total) => {
		setProgress((loaded * 100) / total);
	};

	return (
		<div className="article-cover">
			<svg role="img">
				<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#attachment'} />
			</svg>
			{isUpload && <span className="progress-line" style={{ width: `${uploadProgress}%` }} />}
			<label htmlFor="article-cover">
				{isUploadEroror
					? 'Ошибка загрузки'
					: name
					? name
					: props.previewPicture
					? props.previewPicture.originalFileName
					: 'Загрузить обложку'}
			</label>
			<input type="file" accept="image/*" id="article-cover" onChange={onChange} />
		</div>
	);
};
