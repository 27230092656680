import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputSearch } from '../../atoms';
import { SearchView } from '../index';

import { clearSearch } from 'action/search';
import { KeyboardNav } from 'core/utils';
import { SearchHistory } from '../searchHistory';

import './style.scss';

const HeaderSearch = () => {
	const rootRef = useRef(null);
	const inputRef = useRef(null);
	const viewRef = useRef(null);
	const keyboardNavRef = useRef(null);

	const dispatch = useDispatch();

	const [historyState, setHistoryState] = useState(false);

	const { search } = useSelector(state => ({ search: state.search }));

	function handleClickOutside(event) {
		const searchMainPage = document.querySelector('.search-main');
		const { target } = event;
		if (!searchMainPage) {
			if (
				search.quick !== null &&
				target !== rootRef.current &&
				!rootRef.current.contains(target)
			) {
				if (document.getElementById('search-input'))
					document.getElementById('search-input').value = '';
				dispatch(clearSearch());
			}
		}
	}

	useEffect(() => {
		window.addEventListener('click', handleClickOutside, true);

		const view = viewRef.current;
		const input = inputRef.current;

		if (view && input) {
			const list = [...view.querySelectorAll('button, a:not([tabindex="-1"])')];

			keyboardNavRef.current = new KeyboardNav(
				list,
				input,
				false,
				({ prevItem, nextItem }) => {
					if (prevItem) {
						const prevBlockItem = prevItem.closest('.block-item');
						if (prevBlockItem) {
							prevBlockItem.classList.remove('focus-visible');
						}
					}

					if (nextItem) {
						const nextBlockItem = nextItem.closest('.block-item');
						if (nextBlockItem) {
							nextBlockItem.classList.add('focus-visible');
						}
					}
				}
			);
			keyboardNavRef.current.activateNav();
		}
		return () => {
			window.removeEventListener('click', handleClickOutside, true);
			if (keyboardNavRef.current) keyboardNavRef.current.deactivateNav();
		};
	});

	const clearSearchCallBack = useCallback(() => {
		dispatch(clearSearch());
	}, [dispatch]);

	useEffect(() => {
		return () => {
			if (document.getElementById('search-input'))
				document.getElementById('search-input').value = '';
			clearSearchCallBack();
		};
	}, [clearSearchCallBack]);

	let rootClassName = 'header-search';
	if (search.quick || historyState) rootClassName += ' header-search_open';

	return (
		<div className={rootClassName} ref={rootRef}>
			<InputSearch
				inputRef={inputRef}
				toggleHistory={() => {
					clearSearchCallBack();
					setHistoryState(true);
				}}
			/>
			{search.quick && <SearchView result={search.quick} viewRef={viewRef} />}
			{historyState && (
				<SearchHistory
					closeHandle={() => {
						setHistoryState(false);
					}}
				/>
			)}
		</div>
	);
};

export default HeaderSearch;
