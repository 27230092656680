import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';
import { Settings } from 'react-feather';
import { clearStoreSearchResult, textColor } from '../../utils';
import './style.scss';
import ReactGA from 'react-ga4';

export const ServiceCategoryItem = withRouter(
	({ name, id, categoryIdBreadCrumb, hasChildren, fillText, indexName, closeHandle }) => {
		const [path, setPath] = useState(null);

		useEffect(() => {
			if (hasChildren === 'true') {
				setPath(`/v2/services${categoryIdBreadCrumb}`);
			} else if (hasChildren === 'false') {
				const breadCrumb = categoryIdBreadCrumb.replace(`/${id}`, '');
				setPath(`/v2/services${breadCrumb}/ticket/${id}`);
			}
		}, [setPath, id, categoryIdBreadCrumb, hasChildren]);

		const clickServiceCategoryItemHandler = () => {
			if (closeHandle) closeHandle();
			ReactGA.event({
				category: 'Переходы по результатам поиска',
				action: 'Переход в "ИТ-услуги"',
				label: 'Открытия детального просмотра ИТ-услуги',
			});
			clearStoreSearchResult();
		};

		return (
			<Link
				to={path + `?fromIndex=${indexName}`}
				className="block-item block-item_services"
				onClick={clickServiceCategoryItemHandler}
			>
				<Settings size="18" className="block-item-featherIcon" />
				<div className="block-item-info">
					<span>{textColor(name, fillText)}</span>
				</div>
			</Link>
		);
	}
);
