import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';

import News, {
	Vacancies,
	Contacts,
	User,
	Group,
	Thanks,
	Club,
	Appointments,
	History,
	Documents,
	Professional,
	Walkfame,
	Help,
	Activities,
	Activity,
	Glossary,
	Guide,
	Faq,
	Search,
	Services,
	Admin,
	Forbidden,
	University,
	Literature,
	Competencies,
	Calendar,
	CalendarNY,
	Structure,
	Education,
	Strategy,
	NewsWithArrow,
	Favorites,
	Birthday,
	Gallery,
	HomePage,
	HomePageNews,
	Updates,
	Mirapolis,
	Mentoring,
	CorpValues,
	Achievements,
	Quiz,
	LTR,
	VHI,
	ChatBot,
	ChatBotList,
} from './titles';

export default class Router extends Component {
	render() {
		return (
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/main" element={<HomePage />} />
				<Route path="/main/news/*" element={<HomePageNews />} />

				<Route path="/news" element={<News />} />

				<Route path="/news/*" element={<NewsWithArrow />} />

				<Route path="/user/*" element={<User />} />

				<Route path="/contacts/*" element={<Contacts />} />

				<Route path="/group/*" element={<Group />} />

				<Route path="/group-create/*" element={<Group />} />

				<Route path="/thanks/*" element={<Thanks />} />

				<Route path="/vacancies/*" element={<Vacancies />} />

				<Route path="/club/*" element={<Club />} />

				<Route path="/birthday/*" element={<Birthday />} />

				<Route path="/appointments/*" element={<Appointments />} />

				<Route path="/history/*" element={<History />} />

				<Route path="/maxiProfessional/*" element={<Professional />} />

				<Route path="/walkfame/*" element={<Walkfame />} />

				<Route path="/help/*" element={<Help />} />

				<Route path="/strategy/*" element={<Strategy />} />

				<Route path="/activities/*" element={<Activities />} />
				<Route path="/activities/:id/*" element={<Activity />} />

				<Route strict path="/knowledge/glossary/*" element={<Glossary />} />

				<Route strict path="/knowledge/guide/*" element={<Guide />} />

				<Route strict path="/knowledge/documents/*" element={<Documents />} />

				<Route strict path="/knowledge/faq/*" element={<Faq />} />

				<Route path="/search/*" element={<Search />} />

				<Route path="/services/*" element={<Services />} />
				<Route path="/v2/services/*" element={<Services />} />

				<Route path="/admin/*" element={<Admin />} />

				<Route path="/forbidden/*" element={<Forbidden />} />

				<Route path="/university/literature" element={<Literature />} />

				<Route path="/university/*" element={<University />} />

				<Route path="/competencies/*" element={<Competencies />} />

				<Route path="/calendar/13/*" element={<CalendarNY />} />
				<Route path="/calendar/*" element={<Calendar />} />

				<Route path="/new-structure/*" element={<Structure />} />
				<Route path="/structure/*" element={<Structure />} />

				<Route path="/education/*" element={<Education />} />

				<Route path="/favorites/*" element={<Favorites />} />

				<Route path="/gallery/*" element={<Gallery />} />

				<Route path="/updates/*" element={<Updates />} />

				<Route path="/mirapolis-mobile/*" element={<Mirapolis />} />

				<Route path="/mentoring/*" element={<Mentoring />} />

				<Route path="/corporate-values/*" element={<CorpValues />} />

				<Route path="/achievements/*" element={<Achievements />} />

				<Route path="/quiz/*" element={<Quiz />} />

				<Route path="/ltr/*" element={<LTR />} />

				<Route path="/VHI/*" element={<VHI />} />

				<Route path="/messenger/:id" element={<ChatBot />} />
				<Route path="/messenger" element={<ChatBotList />} />
			</Routes>
		);
	}
}
