import React, { FC, SyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, TypeButton } from '@maxi-innovation/ui-kit-frontend';

import { CheckBox, ModalComponent as Modal } from 'core/components';
import {
	useFormState,
	useRegistrationComplete,
	useShowPersonalDataModal,
} from 'feature/auth-register/model';
import { ModalContent } from './ModalContent';
import { PasswordTextField } from 'shared/ui/PasswordTextField';

const validationSchema = yup.object().shape({
	password: yup
		.string()
		.min(8, 'Пароль не соответствует требованиям')
		.max(32, 'Пароль не соответствует требованиям')
		.required('Введите пароль'),
	passwordCopy: yup
		.string()
		.required('Введите пароль')
		.oneOf([yup.ref('password')], 'Пароли не совпадают'),
});

export const NewPasswordForm: FC = () => {
	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const [checked, setChecked] = useState(false);

	const { isOpen, toggleOpen } = useShowPersonalDataModal();

	const { isLoading, errorMessage, regComplete } = useRegistrationComplete();

	const { setPhoneView, userName } = useFormState();

	const onCbxChange = () => {
		setChecked(prev => !prev);
	};

	const onLinkClick = (e: SyntheticEvent) => {
		e.preventDefault();
		setPhoneView();
	};

	const onPersonalDataLinkClick = (e: SyntheticEvent) => {
		e.preventDefault();
		e.stopPropagation();
		toggleOpen();
	};

	const onSubmit = handleSubmit(data => {
		if (checked) {
			const { password } = data;
			regComplete(password, true);
		}
	});

	return (
		<>
			{isOpen && (
				<Modal width="narrow" className="" onClose={toggleOpen}>
					<ModalContent />
				</Modal>
			)}
			<form className="auth-form" onSubmit={onSubmit}>
				<svg role="img" className="auth-logo">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#entry-red-logo'
						}
					/>
				</svg>
				{!!errorMessage && (
					<span id="new-password-form-error-message" className="auth-form-error-message">
						{errorMessage}
					</span>
				)}
				<div className="auth-new-pass-user">
					<span>{userName}</span>
				</div>
				<div className="auth-new-pass-title">
					<span>Код подтверждения принят</span>
				</div>
				<div className="auth-control-block">
					<PasswordTextField
						ref={register}
						name="password"
						id="auth-registration-new-password"
						className="auth-control_password"
						label="Придумайте надежный пароль"
						description="Пароль должен содержать от 8 до 32 символов"
						isError={!!errors?.password}
						//@ts-ignore
						errorMessage={errors?.password?.message}
					/>
				</div>
				<div className="auth-control-block">
					<PasswordTextField
						ref={register}
						name="passwordCopy"
						className="auth-control_password"
						label="Подтвердите пароль"
						id="auth-registration-new-password-copy"
						isError={!!errors?.passwordCopy}
						//@ts-ignore
						errorMessage={errors?.passwordCopy?.message}
					/>
				</div>
				<div className="auth-new-pass-personal-data">
					<CheckBox
						name="personal-data"
						className=""
						disabled={false}
						label={null}
						checked={checked}
						onChange={onCbxChange}
						id="auth-new-pass-login-personal-data"
						renderLabel={
							<div className="auth-new-pass-personal-data-label">
								Я даю{' '}
								<a
									href="/"
									onClick={onPersonalDataLinkClick}
									id="auth-registration-new-pass-modal"
								>
									согласие
								</a>{' '}
								на обработку персональных данных
							</div>
						}
					/>
				</div>
				<Button
					variant={TypeButton.PRIMARY}
					type="submit"
					className="auth-action-btn"
					id="auth-registration-new-pass-btn"
					disabled={isLoading}
					loading={isLoading}
				>
					Зарегистрироваться
				</Button>
				<div className="auth-form-footer">
					<a
						href="/"
						onClick={onLinkClick}
						id="auth-registration-new-pass-back"
						className="entryPage__link red-text"
					>
						Вернуться
					</a>
				</div>
			</form>
		</>
	);
};
