import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loader, InfoTooltip } from 'core/components';

import { searchItemsList, searchTagsRightsList, clearRightsList } from 'action/searchRightsItem';

import { RenderUserItem } from './renderUserItem';
import { RenderOtherItem } from './renderOtherItem';
import { RenderTagItem } from './renderTagItem';
import { RenderPositionItem } from './renderPositionItem';
import { KeyboardNav } from 'core/utils';

import './style.scss';

const TypeDetection = new Map([
	[
		'user',
		{
			component: RenderUserItem,
			addingFunction: 'addUserToListHandler',
			action: 'searchItemsList',
		},
	],
	[
		'user_position',
		{
			component: RenderPositionItem,
			addingFunction: 'addPositionToListHandler',
			action: 'searchItemsList',
		},
	],
	[
		'department',
		{
			component: RenderOtherItem,
			addingFunction: 'addItemToListHandler',
			action: 'searchItemsList',
		},
	],
	[
		'wiki_role',
		{
			component: RenderOtherItem,
			addingFunction: 'addItemToListHandler',
			action: 'searchItemsList',
		},
	],
	[
		'tag',
		{
			component: RenderTagItem,
			addingFunction: 'addTagToListHandler',
			action: 'searchTagsRightsList',
		},
	],
]);

class FindItemBlock extends Component {
	state = {
		queryText: '',
		userList: [],
	};

	keyboardNav;

	constructor(props) {
		super(props);
		this.searchInput = React.createRef();
		this.dropdown = React.createRef();
		this.finderRef = React.createRef();
	}

	render() {
		let { queryText, userList } = this.state;
		let { label, placeholder, count, hint } = this.props;
		return (
			<div className="find-item" ref={this.finderRef}>
				{label && (
					<div className="find-item__label">
						<span>{label}</span>
						{hint && <InfoTooltip title={hint} />}
					</div>
				)}

				<div className="find-item__search-wrapper">
					<div className="find-item__search">
						<input
							autoComplete="off"
							placeholder={placeholder}
							type="text"
							className="find-item__search-input"
							value={queryText}
							disabled={count === 'single' && userList.length === 1 && true}
							onChange={this.onInputChange}
							onKeyPress={this.addTagAsString}
							ref={this.searchInput}
						/>
						<svg role="img" onClick={this.closeSearchResult}>
							<use
								xlinkHref={
									process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#cross'
								}
							></use>
						</svg>

						<div className="drop-search-list" ref={this.dropdown}>
							{this.renderSearch()}
						</div>
					</div>
					{this.renderUsersRights(userList)}
				</div>
			</div>
		);
	}

	onInputChange = e => {
		e.preventDefault();
		let { value } = e.currentTarget;
		let { type } = this.props;
		this.setState(
			{
				queryText: value,
			},
			() => {
				if (this.state.queryText.length > 2) {
					const RequestType = this.props[TypeDetection.get(type).action];
					if (RequestType) {
						RequestType({ query: this.state.queryText, indexName: type.toUpperCase() });
					} else return null;
				}
			}
		);
	};

	closeSearchResult = e => {
		this.setState({
			queryText: '',
		});
		this.props.clearRightsList();
	};

	clickOutsideHandler = e => {
		if (this.finderRef.current) {
			if (!this.finderRef.current.contains(e.target)) this.closeSearchResult(e);
		}
	};

	renderSearch = () => {
		const { queryText, userList } = this.state;
		const { type, searchRightsItem, disabledList } = this.props;
		//Временно! Проверка типа т.к. всех типов кроме tag приходит массив searchRightsItem[0].objects, для тегов - массив searchRightsItem
		const newArray =
			searchRightsItem && type !== 'tag' ? searchRightsItem[0]?.objects : searchRightsItem;
		if (queryText.length > 2) {
			if (newArray) {
				const items = newArray.filter(item =>
					disabledList
						? !disabledList.find(el => +item.id === +el) &&
						  !userList.find(el => +item.id === +el.id)
						: !userList.find(el => +item.id === +el.id)
				);

				return (
					<div className="find-item__search-list">
						{items.length > 0 ? (
							items.map((item, index) => {
								const ResultType = TypeDetection.get(type).component;
								const AddingFunction = this[TypeDetection.get(type).addingFunction];
								if (ResultType) {
									if (type === 'tag') {
										return (
											<ResultType
												key={index}
												text={item}
												addToListHandler={AddingFunction}
											/>
										);
									} else {
										return (
											<ResultType
												key={item.id}
												{...item}
												addToListHandler={AddingFunction}
											/>
										);
									}
								}
								return null;
							})
						) : (
							<div className="find-item__search-item find-item__search-item_nothing-found">
								По запросу ничего не найдено
							</div>
						)}
					</div>
				);
			}

			return (
				<div className="find-item__search-list">
					<div className="find-item__search-item">
						<Loader />
					</div>
				</div>
			);
		}

		return null;
	};

	addTagAsString = e => {
		if (this.props.type === 'tag' && e.key === 'Enter') this.addTagToArray(e);
	};

	addTagToArray = e => {
		e.preventDefault();
		let { userList, queryText } = this.state;
		let status = true;

		const dropdownItems = this.dropdown.current.querySelectorAll('.find-item__search-item');
		dropdownItems.forEach(el => {
			if (el.classList.contains('focus-visible')) queryText = el.innerText;
		});

		userList.forEach(item => {
			if (item === queryText) status = false;
		});

		if (status) {
			this.setState(
				{
					userList: [queryText, ...userList],
					queryText: '',
				},
				e => this.props.arrayHandler(this.state.userList)
			);
		}
	};

	deleteTagHandler = e => {
		e.preventDefault();
		const { userList } = this.state;
		const { arrayHandler } = this.props;

		const newUserList = userList.filter(item => {
			return item !== e.currentTarget.dataset.name;
		});

		if (arrayHandler) {
			this.setState(
				{
					userList: newUserList,
				},
				e => arrayHandler(this.state.userList)
			);
		}
	};

	renderUserMoreInfo = (moreInfoType, item) => {
		if (moreInfoType === 'position') {
			return item.position && <i>{`${item.position}`}</i>;
		} else if (moreInfoType === 'department') {
			return (
				item.department &&
				(item.department.name ? (
					<i>{`${item.department.name}`}</i>
				) : (
					<i>{`${item.department}`}</i>
				))
			);
		} else return null;
	};

	renderUsersRights = items => {
		const { moreInfoType, type } = this.props;
		return items.map((item, index) => {
			if (type === 'tag') {
				return (
					<div key={index} className="find-item__user">
						<span className="find-item__user-name">{`${item}`}</span>
						<svg role="img" data-name={item} onClick={this.deleteTagHandler}>
							<use
								xlinkHref={
									process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#cross'
								}
							></use>
						</svg>
					</div>
				);
			} else {
				return (
					<div key={item.id} className="find-item__position">
						<div className="find-item__user">
							<span className="find-item__user-name">
								{item.surname !== undefined && `${item.surname}`} {`${item.name}`}
							</span>
							<svg
								role="img"
								data-itemid={item.id}
								onClick={this.deleteRightsHandler}
							>
								<use
									xlinkHref={
										process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#cross'
									}
								></use>
							</svg>
						</div>

						{moreInfoType && this.renderUserMoreInfo(moreInfoType, item)}
					</div>
				);
			}
		});
	};

	addUserToListHandler = e => {
		e.preventDefault();

		let { userList, queryText } = this.state;
		const { arrayHandler, clearRightsList, onAddHandler } = this.props;

		let userInfo = {
			id: e.currentTarget.dataset.itemid,
			name: e.currentTarget.dataset.itemname,
			surname: e.currentTarget.dataset.itemsurname,
			position: e.currentTarget.dataset.position,
		};

		let status = true;
		userList.forEach(item => {
			if (+item.id === +userInfo.id) {
				status = false;
			}
		});

		if (status && arrayHandler) {
			this.setState(
				{
					userList: [userInfo, ...userList],
					queryText: '',
				},
				e => arrayHandler(this.state.userList)
			);

			clearRightsList();
		}

		if (onAddHandler) {
			onAddHandler(userInfo);
			if (queryText) {
				this.setState({
					queryText: '',
				});
				clearRightsList();
			}
		}

		this.searchInput.current.focus();
	};

	addPositionToListHandler = e => {
		e.preventDefault();
		let { userList } = this.state;
		const { arrayHandler, clearRightsList } = this.props;

		let userInfo = {
			id: e.currentTarget.dataset.positionid,
			name: e.currentTarget.dataset.positionname,
			department: e.currentTarget.dataset.positiondepartment,
		};

		let status = true;
		userList.forEach(item => {
			if (+item.id === +userInfo.id) {
				status = false;
			}
		});
		if (status && arrayHandler) {
			this.setState(
				{
					userList: [userInfo, ...userList],
					queryText: '',
				},
				e => arrayHandler(this.state.userList)
			);

			clearRightsList();
		}

		this.searchInput.current.focus();
	};

	addItemToListHandler = e => {
		e.preventDefault();
		let { userList, queryText } = this.state;
		const { arrayHandler, clearRightsList, onAddHandler } = this.props;

		let info = {
			id: e.currentTarget.dataset.itemid,
			name: e.currentTarget.dataset.itemname,
		};

		let status = true;
		userList.forEach(item => {
			if (+item.id === +info.id) {
				status = false;
			}
		});
		if (status && arrayHandler) {
			this.setState(
				{
					userList: [info, ...userList],
					queryText: '',
				},
				e => arrayHandler(this.state.userList)
			);

			clearRightsList();
		}

		if (onAddHandler) {
			onAddHandler(info);
			if (queryText) {
				this.setState({
					queryText: '',
				});
				clearRightsList();
			}
		}

		this.searchInput.current.focus();
	};

	addTagToListHandler = e => {
		e.preventDefault();
		const { userList } = this.state;
		const { arrayHandler, clearRightsList } = this.props;
		const text = e.currentTarget.dataset.text;
		let status = true;

		userList.forEach(item => {
			if (item === text) status = false;
		});

		if (status && arrayHandler) {
			this.setState(
				{
					userList: [text, ...userList],
					queryText: '',
				},
				e => arrayHandler(this.state.userList)
			);

			clearRightsList();
		}

		this.searchInput.current.focus();
	};

	deleteRightsHandler = e => {
		e.preventDefault();
		let { userList } = this.state;
		const { arrayHandler, onRemoveHandler } = this.props;

		let newUserList = userList.filter(item => {
			return +item.id !== +e.currentTarget.dataset.itemid;
		});

		if (arrayHandler) {
			this.setState(
				{
					userList: newUserList,
				},
				e => arrayHandler(this.state.userList)
			);
		}

		if (onRemoveHandler) {
			onRemoveHandler(+e.currentTarget.dataset.itemid);
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.clickOutsideHandler);
		const items = this.dropdown.current.querySelectorAll('.find-item__search-item');
		const list = [...items];
		this.keyboardNav = new KeyboardNav(
			list,
			this.searchInput,
			true,
			({ prevItem, nextItem }) => {
				if (prevItem) {
					const prevBlockItem = prevItem.closest('.find-item__search-item');
					if (prevBlockItem) {
						prevBlockItem.classList.remove('focus-visible');
					}
				}

				if (nextItem) {
					const nextBlockItem = nextItem.closest('.find-item__search-item');
					if (nextBlockItem) {
						nextBlockItem.classList.add('focus-visible');
					}
				}
			}
		);

		const { currentItemsList, arrayHandler, count } = this.props;
		if (currentItemsList && arrayHandler) {
			if (count === 'single') {
				this.setState(
					{
						userList: [currentItemsList, ...this.state.userList],
					},
					e => arrayHandler(this.state.userList)
				);
			} else {
				this.setState(
					{
						userList: [...currentItemsList, ...this.state.userList],
					},
					e => arrayHandler(this.state.userList)
				);
			}
		}
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.clickOutsideHandler);
		this.props.clearRightsList();
		this.keyboardNav.deactivateNav();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentItemsList !== this.props.currentItemsList) {
			if (this.props.currentItemsList && this.props.arrayHandler) {
				this.setState(
					{
						userList: [...this.props.currentItemsList],
					},
					e => this.props.arrayHandler(this.state.userList)
				);
			} else {
				this.setState({
					userList: [],
				});
			}
		}

		if (this.keyboardNav) {
			if (this.dropdown.current) {
				const list = [...this.dropdown.current.querySelectorAll('.find-item__search-item')];
				this.keyboardNav.setList(list);
				this.keyboardNav.activateNav();
			}

			if (!this.dropdown.current) {
				this.keyboardNav.deactivateNav();
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		searchRightsItem: state.searchRightsItem,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		searchItemsList: data => {
			dispatch(searchItemsList(data));
		},
		searchTagsRightsList: data => {
			dispatch(searchTagsRightsList(data));
		},
		clearRightsList: () => {
			dispatch(clearRightsList());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(FindItemBlock);
