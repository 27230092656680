import React from 'react';

export const Ul = () => {
	return (
		<svg
			width="512"
			height="367.9"
			viewBox="0 0 512 367.9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M146.3 292.6h365.7v73.1h-365.7zM146.3 148.5h365.7v73.1h-365.7zM146.3 0h365.7v73.1h-365.7zM0 294.8h73.1v73.1h-73.1zM0 148.5h73.1v73.1h-73.1zM0 0h73.1v73.1h-73.1z" />
		</svg>
	);
};
