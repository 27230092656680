import React from 'react';

import './style.scss';

export const FormInputBlock = ({ children, className = '', id = '' }) => {
	return (
		<div className={`input-block ${className}`} id={id}>
			{children}
		</div>
	);
};
