import React from 'react';

export const Volume = ({ onClick }) => {
	return (
		<svg
			width="22"
			height="16.1"
			viewBox="0 0 22 16.1"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path d="M10.4.2c-.3-.2-.7-.2-1 .1l-4.8 3.8h-3.6c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1h3.6l4.7 3.8c.2.1.4.2.6.2.1 0 .3 0 .4-.1.3-.2.6-.5.6-.9v-14c.1-.4-.1-.8-.5-.9zm-1.4 12.8l-3.4-2.7c-.2-.1-.4-.2-.6-.2h-3v-4h3c.2 0 .4-.1.6-.2l3.4-2.7v9.8zM18.8.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4 3.5 3.5 3.5 9.2 0 12.7-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c4.3-4.2 4.3-11.2 0-15.5zM13.8 3.8c-.4.4-.4 1 0 1.4 1.6 1.6 1.6 4.1 0 5.7-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c2.3-2.3 2.3-6.1 0-8.5-.3-.4-1-.4-1.4 0z" />
		</svg>
	);
};
