import React, { PureComponent } from 'react';

import { secondsToTime } from './utils/secondsToTime';
import { Maximazie, Pause, Play, Volume } from 'shared/ui/svg';

import './style.scss';

export class MIVideoPlayer extends PureComponent {
	state = {
		duration: `00:00`,
		currentTime: `00:00`,
		progress: 0,
		volume: 1,
		userVolume: 1,
	};
	render() {
		let { currentTime, duration, progress, volume } = this.state;
		let { url, posterUrl } = this.props;
		return (
			<div
				className="MIVideoPlayer pause"
				ref={el => (this.player = el)}
				onClick={this.tooglePlay}
			>
				<video
					preload="metadata"
					src={`${url}`}
					className="MIVideoPlayer__video"
					ref={el => (this.video = el)}
					controlsList="nodownload"
					poster={posterUrl || ''}
					requestfullscreen="true"
				></video>

				<span className="MIVideoPlayer__btn">
					<Play />
				</span>

				<div className="MIVideoPlayer__toolbar" onClick={this.stopPropogationToolbar}>
					<div
						className="toolbar__timeline"
						onClick={this.timeLineHandler}
						ref={el => (this.timeLine = el)}
					>
						<span style={{ width: `${progress}%` }}></span>
					</div>
					<div className="toolbar__panels">
						<div className="panels__tools">
							<div className="tools__playOrPause">
								<Play onClick={this.tooglePlay} />
								<Pause onClick={this.tooglePlay} />
							</div>
							<div className="tools__volume">
								<Volume onClick={this.onMute} />
								<div
									className="volume"
									onClick={this.onChangeVolume}
									ref={el => (this.volume = el)}
								>
									<span style={{ width: `${volume * 100}%` }}></span>
								</div>
							</div>
							<div className="tools__time">
								<span>{`${currentTime} / ${duration}`}</span>
							</div>
						</div>
						<div className="panels__fullsize">
							<Maximazie onClick={this.onFullScreen} />
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.video.addEventListener('loadedmetadata', this.onLoadMetaData, false);
		this.video.addEventListener('timeupdate', this.onTimeUpdate, false);
		document.addEventListener('click', this.onClickPastPlayer, false);
	}

	componentWillUnmount() {
		this.video.removeEventListener('loadedmetadata', this.onLoadMetaData, false);
		this.video.removeEventListener('timeupdate', this.onTimeUpdate, false);
		document.removeEventListener('click', this.onClickPastPlayer, false);
	}

	onLoadMetaData = e => {
		if (this.props.isEdit) {
			this.video.currentTime = 0;
		}
		this.setState({
			duration: secondsToTime(e.target.duration),
			volume: e.target.volume,
		});
	};

	onTimeUpdate = () => {
		this.setState({
			currentTime: secondsToTime(this.video.currentTime),
			progress: (this.video.currentTime / this.video.duration) * 100,
		});
		if (this.video.currentTime === this.video.duration) {
			this.player.classList.add('pause');
			this.video.pause();
		}
	};

	tooglePlay = () => {
		if (this.video.paused) {
			const playPromise = this.video.play();
			if (!!playPromise) {
				playPromise
					.then(() => {
						this.video.play();
					})
					.catch(() => {
						this.video.play();
					});
			}
			this.player.classList.remove('pause');
		} else {
			const playPromise = this.video.pause();
			if (!!playPromise) {
				playPromise
					.then(() => {
						this.video.pause();
					})
					.catch(() => {
						this.video.pause();
					});
			}
			this.player.classList.add('pause');
		}
	};

	onFullScreen = () => {
		let fullscreenElement =
			document.fullscreenElement /* Standard syntax */ ||
			document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */ ||
			document.mozFullScreenElement /* Firefox syntax */ ||
			document.msFullscreenElement;
		if (fullscreenElement === undefined || fullscreenElement === null) {
			if (this.player.requestFullscreen) {
				this.player.requestFullscreen();
			} else if (this.player.mozRequestFullScreen) {
				/* Firefox */
				this.player.mozRequestFullScreen();
			} else if (this.player.webkitRequestFullscreen) {
				/* Chrome, Safari and Opera */
				this.player.webkitRequestFullscreen();
			} else if (this.player.msRequestFullscreen) {
				/* IE/Edge */
				this.player.msRequestFullscreen();
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				/* Firefox */
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				/* Chrome, Safari and Opera */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				/* IE/Edge */
				document.msExitFullscreen();
			}
		}
	};

	stopPropogationToolbar = e => {
		e.stopPropagation();
		e.preventDefault();
	};

	timeLineHandler = e => {
		let progress = this.timeLine.getBoundingClientRect();
		let maxduration = this.video.duration;
		let position = e.pageX - progress.left;
		let percentage = (100 * position) / (progress.right - progress.left);
		if (percentage > 100) {
			percentage = 100;
		}
		if (percentage < 0) {
			percentage = 0;
		}
		this.video.currentTime = (maxduration * percentage) / 100;
	};

	onChangeVolume = e => {
		let progress = this.volume.getBoundingClientRect();
		let maxduration = 1;
		let position = e.pageX - progress.left;
		let percentage = (100 * position) / (progress.right - progress.left);

		if (percentage > 100) {
			percentage = 100;
		}
		if (percentage < 0) {
			percentage = 0;
		}
		this.video.volume = (maxduration * percentage) / 100;
		this.video.muted = false;
		this.setState({
			volume: (maxduration * percentage) / 100,
			userVolume: (maxduration * percentage) / 100,
		});
	};

	onMute = () => {
		if (this.video.muted) {
			this.video.muted = false;
			this.setState({
				volume: this.state.userVolume,
			});
		} else {
			this.video.muted = true;
			this.setState({
				volume: 0,
			});
		}
	};

	onClickPastPlayer = e => {
		let item = this.player;
		const { target } = e;
		if (target !== item && !item.contains(target)) {
			this.player.classList.add('pause');
			this.video.pause();
		}
	};
}
