import React from 'react';
import { useNavigate } from 'react-router-dom';

import './style.scss';

const BackArrow = ({ link }) => {
	const navigate = useNavigate();
	const goBackToList = e => {
		e.preventDefault();
		if (link) {
			navigate(link);
		} else {
			navigate(-1);
		}
	};

	return (
		<div className="back-btn__wrapper">
			<div className="back-btn" onClick={goBackToList}>
				<svg role="img">
					<use
						xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#arrow'}
					></use>
				</svg>

				<span>Назад</span>
			</div>
		</div>
	);
};

export default BackArrow;
