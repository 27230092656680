import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';

import { Editor } from 'components/editorjsText';

import Notification from 'modules/notification';
import { NotificationHelper } from 'action/notificationHelper';

import { Button } from 'core/components';

import { getArticle, deleteArticle, articlesEdit } from 'rest/article';

import { Header } from '../../organisms/header';
import { Wrapp } from '../../atoms/mainWrapp';
import { Text, HeaderWrapp } from '../../atoms/header';
import { ArticleLoader } from '../../organisms/loader';
import { DeleteModal } from '../../organisms/deleteModal';

import { CoverUploader } from '../coverUploader';

export const ArticleGroupEdit = () => {
	const navigate = useNavigate();
	const params = useParams();
	const [coverId, setCoverId] = useState(null);
	const [article, setArticle] = useState(null);
	const [newContent, setNewContent] = useState('');
	const [name, setName] = useState('');
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

	const dispatch = useDispatch();

	const fetchDocument = useCallback(async () => {
		const articleId = params.idArticle.split('?')[0];

		const res = await getArticle({
			groupId: params.id,
			articleId,
		});
		if (res.data.responseCode === 0) {
			let newContent = {
				...res.data.response,
			};

			setArticle(newContent);
			setName(newContent.name);
		} else {
			dispatch(NotificationHelper(res.data.errorMessage, 1));
			navigate(`/group/${params.id}/article/${articleId}`);
		}
	}, [params.id, params.idArticle, dispatch, navigate]);

	useEffect(() => {
		fetchDocument();
	}, [fetchDocument]);

	const toggleDeleteModal = () => {
		setIsDeleteModalOpened(!isDeleteModalOpened);
	};

	const deleteHandler = async () => {
		const articleId = params.idArticle.split('?')[0];
		const res = await deleteArticle(articleId);
		if (res.data.responseCode === 0) {
			dispatch(NotificationHelper('Статья удалена', 0));
			navigate(`/group/${params.id}/article`);
		} else {
			dispatch(NotificationHelper(res.data.errorMessage, 1));
		}
		toggleDeleteModal();
	};

	const nameHandler = e => {
		e.currentTarget.classList.remove('validation-error');
		setName(e.currentTarget.value);
	};

	const setCoverIdHandler = coverId => {
		setCoverId(coverId);
	};

	const isValid = () => {
		const node = document.querySelector('.article-name');
		const name = node.value;
		const result = name.replace(/\s/g, '').length !== 0 && name.length <= 255;
		toogleErrorClass(!result);
		return result;
	};

	const toogleErrorClass = status => {
		const node = document.querySelector('.article-name');
		if (status) {
			node.classList.add('validation-error');
		} else {
			node.classList.remove('validation-error');
		}
	};

	const draftHandler = () => {
		editArticleHandler('DRAFT');
	};

	const publishHandler = () => {
		editArticleHandler('PUBLISH');
	};

	const editArticleHandler = async status => {
		const articleId = params.idArticle.split('?')[0];
		if (isValid()) {
			if (newContent) {
				const res = await articlesEdit({
					name,
					previewPicture: coverId ? coverId : article?.previewPicture?.id,
					content: newContent,
					status,
					id: articleId,
				});

				if (res.data.responseCode === 0) {
					navigate(`/group/${params.id}/article/${articleId}`);
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			} else {
				dispatch(NotificationHelper('Необходимо указать содержание статьи', 1));
			}
		}
	};

	if (article) {
		return (
			<div className="Article-create">
				<Notification />

				{isDeleteModalOpened && (
					<DeleteModal
						title="Удаление статьи"
						name={article.name}
						text={`Статья <b>${article.name}</b> будет удалена навсегда. Если Вы уверены, что хотите удалить статью навсегда, введите название <b>${article.name}</b> в поле ниже.`}
						onAccept={deleteHandler}
						onCancel={toggleDeleteModal}
					/>
				)}

				<Header>
					<HeaderWrapp>
						<Helmet title={`Редактирование "${article.name}"`} />
						<Text type="bold">Редактирование статьи</Text>
						<div className="Aheader-block">
							<Button
								type="primary"
								typeButton="button"
								text="Опубликовать"
								handler={publishHandler}
								image={null}
							/>
							<Button
								type="secondary"
								typeButton="button"
								text="В черновик"
								handler={draftHandler}
								image={null}
							/>
							<Button
								type="secondary"
								typeButton="button"
								image={null}
								text="Удалить"
								handler={toggleDeleteModal}
							/>
							<Link
								to={`/group/${params.id}/article/${params.idArticle}`}
								className="Aheader-btn Aheader-btn_special"
							>
								Отменить
							</Link>
						</div>
					</HeaderWrapp>
				</Header>
				<Wrapp>
					<div className="article-form article-form_create">
						<CoverUploader
							setCoverId={setCoverIdHandler}
							previewPicture={article.previewPicture}
						/>
						<input
							type="text"
							className="article-name"
							onChange={nameHandler}
							placeholder="Заголовок статьи"
							defaultValue={article.name}
						/>
					</div>

					<Editor getContent={setNewContent} content={article.content} />
				</Wrapp>
			</div>
		);
	} else {
		return (
			<div className="Article-create">
				<Header>
					<HeaderWrapp></HeaderWrapp>
				</Header>
				<Wrapp>
					<ArticleLoader />
				</Wrapp>
			</div>
		);
	}
};
