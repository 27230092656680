import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';
import ReactGA from 'react-ga4';

import { textColor } from '../../utils';

import './style.scss';

export const DocsItem = withRouter(
	({ name, id, themeId = 1, lastUpdateDate, fillText, indexName, closeHandle }) => {
		const clickDocItemHandler = () => {
			if (closeHandle) closeHandle();
			ReactGA.event({
				category: 'Переходы по результатам поиска',
				action: 'Переход в "Нормативные документы"',
				label: 'Открытия детального просмотра нормативных документов',
			});
		};

		return (
			<Link
				to={`/knowledge/documents/view/${themeId}/${id}?fromIndex=${indexName}`}
				className="block-item block-item_guide"
				onClick={clickDocItemHandler}
			>
				<svg role="img" className="stroke-svg">
					<use
						xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#search-docs'}
					/>
				</svg>
				<div className="block-item-info">
					<span className="block-item-short-text">{textColor(name, fillText)}</span>
					<div className="block-item-wrapper">
						<span className="block-item-folder">
							<span>{textColor(lastUpdateDate, fillText)}</span>
						</span>
					</div>
				</div>
			</Link>
		);
	}
);
