import { PulseSurveyList } from 'pages/survey/PulseSurveyList';
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const HomePage = lazy(() => import('../homePage'));
const FaqMain = lazy(() => import('../university/pages/faqMain'));
const NewContacts = lazy(() => import('../contacts'));
const UserPage = lazy(() => import('../user'));
const GroupPage = lazy(() => import('../group'));
const PostPage = lazy(() => import('../feed'));
const ThanksPage = lazy(() => import('../thanks'));
const VacanciesPage = lazy(() => import('../vacancies'));
const ClubPage = lazy(() => import('../club'));
const ClubPageKPP = lazy(() => import('../../pages/club'));
const AppointmentsPage = lazy(() => import('../appointments'));
const HistoryPage = lazy(() => import('../history'));
const Knowledge = lazy(() => import('../knowledge'));
const Guide = lazy(() => import('../guide'));
const WalkFamePage = lazy(() => import('../walkfame'));
const Activities = lazy(() => import('../activities'));
const Services = lazy(() => import('../services'));
const Passes = lazy(() => import('../passes'));
const NewItServices = lazy(() => import('../it-services_new'));
const University = lazy(() => import('../university'));
const Competencies = lazy(() => import('../competencies'));
const Trips = lazy(() => import('../trips'));
const Calendar = lazy(() => import('../calendar'));
const Education = lazy(() => import('../education'));
const BusinessCard = lazy(() => import('../businessCard'));
const Birthday = lazy(() => import('../birthday'));
const StrategyPage = lazy(() => import('../../pages/strategy'));
const Favorites = lazy(() => import('../favorites'));
const Gallery = lazy(() => import('../gallery'));
const Updates = lazy(() => import('../updates'));
const NormativeDocument = lazy(() => import('../normativeDocument'));

const Admin = lazy(() => import('../admin'));
const NoPage = lazy(() => import('../noPage'));
const ForbiddenPage = lazy(() => import('../forbiddenPage'));

const Camera = lazy(() => import('../../core/camera'));

const Search = lazy(() => import('../search/pages/main'));
const SearchResult = lazy(() => import('../search/pages/result'));

const MobileMenu = lazy(() => import('../bottomMenu/menu'));

const Structure = lazy(() => import('../structure'));
const StructurePage = lazy(() => import('../../pages/structure'));

const MirapolisMobile = lazy(() => import('../mirapolis-mobile-hub'));

const Articles = lazy(() => import('../new-article'));

const MentoringPage = lazy(() => import('../../pages/mentoring'));
const RetailShiftsPage = lazy(() => import('../../pages/retailShifts'));
const RetailShiftsPageEdit = lazy(() => import('../../pages/retailShifts/Edit'));

const HotLinePage = lazy(() => import('../../pages/hotLine'));
const CorporateValuesPage = lazy(() => import('../../pages/corporateValues'));
const AchievementsPage = lazy(() => import('../../pages/achievements'));
const QuizPage = lazy(() => import('../../pages/quiz'));
const CropperPage = lazy(() => import('../../pages/cropper'));
const VacationPage = lazy(() => import('../../pages/vacation'));

const PageStatements = lazy(() => import('../../pages/statements'));

const CourierPage = lazy(() => import('../../pages/courier'));
const PageLTR = lazy(() => import('../../pages/LTR'));
const PageSurvey = lazy(() => import('../../pages/survey'));
const PagePulseSurvey = lazy(() => import('../../pages/survey/PagePulseSurvey'));
const SurveyChildsPage = lazy(() => import('../../pages/survey/SurveyChildsPage'));
const WorkplacePage = lazy(() => import('../../pages/workplace'));
const InventoryPage = lazy(() => import('../../pages/inventory'));

const DocumentCreate = lazy(() => import('../new-article/pages/documentCreate'));
const DocumentEdit = lazy(() => import('../new-article/pages/documentEdit'));
const DocumentView = lazy(() => import('../new-article/pages/documentView'));
const MessengerPage = lazy(() => import('../../pages/messenger'));
const PageDMS = lazy(() => import('../../pages/DMS'));
const PageMaxiProfessional = lazy(() => import('../../pages/maxiProfessional'));
const RepairServicesPage = lazy(() => import('../../pages/repairServices'));
const OfficeMapPage = lazy(() => import('../../pages/officeMap'));

const Router = () => {
	return (
		<Routes>
			<Route path="/*" element={<HomePage />} />
			<Route strict path="/main" element={<HomePage />} />
			<Route strict path="/main/thanks/:thankId" element={<HomePage />} />
			<Route path="/main/news/:idPost" element={<HomePage />} />
			<Route path="/main/news/:idPost/:idPoll" element={<HomePage />} />

			<Route strict path="/media" element={<Camera />} />

			<Route path="/group/*" element={<GroupPage />} />

			<Route path="/contacts/*" element={<NewContacts />} />

			<Route path="/club/*" element={<ClubPage />} />
			<Route path="/club/maxi-club-kpp/*" element={<ClubPageKPP />} />

			<Route path="/VHI/*" element={<PageDMS />} />

			<Route path="/gallery/*" element={<Gallery />} />

			<Route path="/education/*" element={<Education />} />

			<Route path="/knowledge/guide/*" element={<Guide />} />

			<Route path="/mentoring/*" element={<MentoringPage />} />

			<Route path="/ltr/*" element={<PageLTR />} />

			<Route path="/knowledge/glossary/*" element={<Knowledge />} />

			<Route path="/user/:id/*" element={<UserPage />} />

			<Route path="/news/*" element={<PostPage />} />

			<Route path="/thanks" element={<ThanksPage />} />
			<Route path="/thanks/:id" element={<ThanksPage />} />

			<Route path="/vacancies/*" element={<VacanciesPage />} />

			<Route path="/admin/*" element={<Admin />} />

			<Route path="/appointments/*" element={<AppointmentsPage />} />

			<Route path="/history" element={<HistoryPage />} />
			<Route path="/corporate-values" element={<CorporateValuesPage />} />

			<Route path="/birthday/*" element={<Birthday />} />

			<Route path="/strategy" element={<StrategyPage />} />
			<Route path="/strategy/:id" element={<StrategyPage />} />

			<Route path="/maxiProfessional/*" element={<PageMaxiProfessional />} />

			<Route path="/forbidden" element={<ForbiddenPage />} />

			<Route path="/search" element={<Search />} />
			<Route path="/search/:entity/:query" element={<SearchResult />} />

			<Route path="/menu" element={<MobileMenu />} />

			<Route path="/walkfame/*" element={<WalkFamePage />} />

			<Route path="/activities/*" element={<Activities />} />

			<Route path="/services/*" element={<Services />} />

			<Route path="/services/courier/*" element={<CourierPage />} />

			<Route path="/services/vacation/*" element={<VacationPage />} />

			<Route path="/services/workplace/*" element={<WorkplacePage />} />

			<Route path="/services/inventory/*" element={<InventoryPage />} />

			<Route path="/services/hot-line/*" element={<HotLinePage />} />

			<Route path="/services/statements/*" element={<PageStatements />} />

			<Route path="/services/trips/*" element={<Trips />} />

			<Route path="/services/retail-shifts" element={<RetailShiftsPage />} />
			<Route path="/services/retail-shifts/edit" element={<RetailShiftsPageEdit />} />
			<Route path="/services/retail-shifts/:id" element={<RetailShiftsPage />} />

			<Route path="/services/passes/:id/*" element={<Passes />} />

			<Route path="/services/repair/*" element={<RepairServicesPage />} />
			<Route path="/services/office-map/*" element={<OfficeMapPage />} />
			<Route path="/services/business-card/*" element={<BusinessCard />} />

			<Route path="/v2/services/*" element={<NewItServices />} />

			<Route path="/university/*" element={<University />} />
			<Route path="/knowledge/faq/*" element={<FaqMain />} />

			<Route path="/calendar/:id" element={<Calendar />} />

			<Route path="/competencies" element={<Competencies />} />

			<Route path="/new-structure" element={<Structure />} />
			<Route path="/structure" element={<StructurePage />} />

			<Route path="/favorites/*" element={<Favorites />} />

			<Route path="/updates" element={<Updates />} />

			<Route path="/404" element={<NoPage />} />

			<Route path="/mirapolis-mobile" element={<MirapolisMobile />} />

			<Route path="/achievements/*" element={<AchievementsPage />} />

			<Route path="/quiz/*" element={<QuizPage />} />
			<Route path="/cropper/*" element={<CropperPage />} />

			<Route path="/services/pulse-survey/list" element={<PulseSurveyList />} />
			<Route path="/services/pulse-survey/*" element={<PagePulseSurvey />} />
			<Route path="/services/survey/*" element={<PageSurvey />} />
			<Route path="/special-survey/*" element={<SurveyChildsPage />} />

			<Route path="/knowledge/documents/create/:themeId" element={<DocumentCreate />} />
			<Route path="/knowledge/documents/edit/:themeId/:id" element={<DocumentEdit />} />
			<Route path="/knowledge/documents/view/:themeId/:id" element={<DocumentView />} />
			<Route path="/knowledge/documents/*" element={<NormativeDocument />} />

			<Route path="/messenger/*" element={<MessengerPage />} />

			<Route element={<NoPage />} />
		</Routes>
	);
};

export default Router;
