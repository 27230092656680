import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TextField, Button, TypeButton, Typography } from '@maxi-innovation/ui-kit-frontend';

import { useFormState, useRecoverPhone } from 'feature/auth-recover/model';
import { getRestPhone } from 'plugins/getRestPhone';

const validationSchema = yup.object().shape({
	phone: yup
		.string()
		.required()
		.test('phone-format', 'Неверный формат номера телефона', value => {
			return value.length === 16;
		})
		.test('phone-format-number', 'Неверный формат номера телефона', value => {
			const phone = value.replace(/[^\d]/g, '').substr(1);
			return phone.length === 10;
		}),
});

export const PhoneForm = () => {
	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const { phone, setPhone } = useFormState();
	const { isLoading, errorMessage, getCode } = useRecoverPhone();

	const onSubmit = handleSubmit(async data => {
		const { phone } = data;
		const clearPhone = getRestPhone(phone);
		setPhone(phone);
		getCode(clearPhone);
	});

	return (
		<form className="auth-form" onSubmit={onSubmit}>
			<svg role="img" className="auth-logo">
				<use
					xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#entry-red-logo'}
				/>
			</svg>
			{!!errorMessage && (
				<span className="auth-form-error-message" id="auth-recover-error-message">
					{errorMessage}
				</span>
			)}
			<div className="auth-control-block">
				<Typography as="span" variant="bodyRegular">
					Введите Ваш личный номер телефона
				</Typography>

				<TextField
					variant="default"
					ref={register}
					name="phone"
					id="auth-recover-control-phone"
					error={errors?.phone}
					errorMessage={errors?.phone?.message}
					defaultValue={phone}
					mask={{
						mask: '+{7} #00 000-00-00',
						definitions: {
							'#': /[01234569]/,
						},
						lazy: false,
						placeholderChar: '_',
					}}
				/>
			</div>
			<Button
				variant={TypeButton.PRIMARY}
				type="submit"
				className="auth-action-btn"
				id="auth-recover-phone-btn"
				disabled={isLoading}
				loading={isLoading}
			>
				Получить код
			</Button>
			<div className="auth-form-footer">
				<Link to="/login" className="entryPage__link red-text" id="auth-recover-back">
					Вернуться
				</Link>
			</div>
		</form>
	);
};
