import React, { FC, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import { ISelectHandle, IProps } from '../model/types';
import { useGetSearchResult } from '../model/useGetSearchResult';
import { ResultToList } from './ResultToList';
import useClickOutside from 'hooks/useClickOutside';
import { InfoTooltip } from 'core/components';
import useTimeout from 'shared/hooks/useTimeout';

export const Field: FC<IProps> = ({
	label,
	placeholder,
	onSelected,
	onChange,
	maxLength,
	error,
	name,
	id,
	defaultValue = '',
	searchType,
	searchTypes,
	limit = '10',
	withoutMe,
	disabled,
	inputRef: parentRef,
	border,
	multiple,
	description,
	groupId,
	tooltip,
}) => {
	const timeout = useTimeout();
	const finderRef = useRef<HTMLDivElement | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [inputFocus, setInputFocus] = useState<boolean>(false);
	const [inputValueLength, setInputValueLength] = useState<number>(
		defaultValue ? defaultValue.length : 0
	);

	const cbRef = useCallback(
		(elem: any) => {
			inputRef.current = elem;

			if (!parentRef) return;
			if (typeof parentRef === 'function') {
				parentRef(elem);
			} else {
				parentRef.current = elem;
			}
		},
		[inputRef, parentRef]
	);

	const {
		searchTrigger,
		searchPostTrigger,
		resultList,
		clearResultList,
		searchTagTrigger,
		multiSearchTrigger,
	} = useGetSearchResult();

	const checkTypeSearch = (value: any) => {
		if (groupId) {
			// Если присылаем параметр groupId,
			// то поиск производим по постам этой группы
			searchPostTrigger({
				groupId,
				searchString: value,
				limit,
			});
		} else if (searchType === 'TAG') {
			searchTagTrigger({
				query: value,
				indexName: searchType,
			});
		} else {
			if (searchType) {
				searchTrigger({
					query: value,
					indexName: searchType,
					limit,
					withoutMe,
				});
			}

			if (searchTypes) {
				multiSearchTrigger({
					query: value,
					indexNames: searchTypes,
					limit,
					withoutMe,
				});
			}
		}
	};

	const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/^\s+|\s+$/g, '');

		if (maxLength && value.length > maxLength) return;
		if (onChange) onChange(value);

		setInputValueLength(value.length);
		if (value.length > 2) {
			timeout(() => checkTypeSearch(value));
		} else {
			clearResultList();
		}
	};

	const openHandle = () => setInputFocus(true);
	const closeHandle = () => setInputFocus(false);
	useClickOutside(finderRef, closeHandle);

	const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Escape' || event.key === 'Tab') {
			closeHandle();
		}
	};

	const selectHandle = (item: ISelectHandle) => {
		if (onSelected) onSelected(item);

		if (item.name) setInputValueLength(item.name.length);
		closeHandle();
		clearResultList();

		if (!inputRef.current) return;

		inputRef.current.value = multiple ? '' : item.name;
	};

	return (
		<div
			className={classNames('finder-field', {
				focused: inputFocus,
				error,
				border,
			})}
			ref={finderRef}
		>
			{label && (
				<label htmlFor={id} className="finder-field__label">
					<span>{label}</span>
					{tooltip && (
						<InfoTooltip
							// @ts-ignore
							title={tooltip}
						/>
					)}
				</label>
			)}

			<div className="finder-field__wrap">
				<input
					id={id}
					className="finder-field__input"
					name={name}
					placeholder={placeholder}
					defaultValue={defaultValue}
					onChange={onChangeHandle}
					onFocus={openHandle}
					onKeyDown={keyDownHandler}
					ref={cbRef}
					disabled={disabled}
					autoComplete="off"
					maxLength={maxLength}
				/>

				{!!maxLength && (
					<div className="finder-field__counter">{`${inputValueLength} / ${maxLength}`}</div>
				)}

				{!!resultList.length && inputFocus && (
					<ResultToList resultList={resultList} selectHandle={selectHandle} />
				)}
			</div>

			{description && <div className="finder-field-description">{description}</div>}
		</div>
	);
};
