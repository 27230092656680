import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export const AdminActionBtn = ({ handler, img, text }) => {
	return (
		<div className="admin-btn">
			<svg role="img" onClick={handler} className="admin-btn__svg">
				<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + `/img/sprite.svg#${img}`}></use>
			</svg>
			<span className="admin-btn__text">{text}</span>
		</div>
	);
};

AdminActionBtn.propTypes = {
	handler: PropTypes.func.isRequired,
	img: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};
