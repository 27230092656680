import React, { Component } from 'react';

import './style.scss';

import { FormItem } from '../../molecules';

class VacancyResponseForms extends Component {
	state = {
		isFormSuggest: false,
	};

	render() {
		let { isFormSuggest } = this.state;
		let { claimId } = this.props;
		return (
			<div className="vacancies-view__forms">
				<div className="forms-tabs">
					<div className="forms-tabs__wrapper">
						<span
							className={
								!isFormSuggest ? 'forms-tabs__item active' : 'forms-tabs__item'
							}
							onClick={this.changeFormHandler}
						>
							Откликнуться самому
						</span>
						<span
							className={
								isFormSuggest ? 'forms-tabs__item active' : 'forms-tabs__item'
							}
							onClick={this.changeFormHandler}
						>
							Предложить кандидата
						</span>
					</div>
				</div>

				<div className="forms-responses">
					<FormItem isFormSuggest={isFormSuggest} claimId={claimId} />

					<div className="forms-responses__svg">
						<svg role="img">
							<use
								xlinkHref={
									process.env.REACT_APP_PUBLIC_URL +
									'/img/sprite.svg#all-vacancies'
								}
							></use>
						</svg>
					</div>
				</div>
			</div>
		);
	}

	changeFormHandler = e => {
		e.preventDefault();
		this.setState({
			isFormSuggest: !this.state.isFormSuggest,
		});
	};
}

export default VacancyResponseForms;
