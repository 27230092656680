import React from 'react';
import { useDispatch } from 'react-redux';
import { setNotification } from 'action/notification';

export default function useNotify() {
	const dispatch = useDispatch();
	const timeoutRef = React.useRef(null);

	const setNotify = React.useCallback(
		(notification = {}) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			const { text = '', status = 0 } = notification;
			dispatch(
				setNotification({
					isOpen: true,
					status,
					text,
				})
			);
			timeoutRef.current = setTimeout(() => {
				dispatch(
					setNotification({
						isOpen: false,
						status: 0,
						text: '',
					})
				);
			}, 5000);
		},
		[dispatch]
	);

	return setNotify;
}
