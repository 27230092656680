import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';

import './style.scss';

export const MainFaqLink = withRouter(({ id, name, questionCount, location }) => {
	const { state } = location;
	return (
		<Link
			to={{
				pathname: `/knowledge/faq/${id}`,
				state: {
					...state,
					back: -1,
				},
			}}
			className="knowledge-main-faq-link"
		>
			<span className="faq-link__name">{name}</span>
			<span className="faq-link__info">Вопросов в разделе: {questionCount}</span>
		</Link>
	);
});
