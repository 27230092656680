import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withRouter = Component => {
	function ComponentWithRouterProp(props) {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();

		return (
			<Component
				{...props}
				location={location}
				navigate={navigate}
				params={params}
				match={{
					params,
					path: location.pathname,
					serch: location.serch,
					hash: location.hash,
				}}
			/>
		);
	}

	return ComponentWithRouterProp;
};
