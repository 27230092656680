import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.scss';

export const PlusIconLink = props => {
	return (
		<Link to={props.to || '/'} className="add-item-link">
			<svg role="img" className="add-item-link__svg">
				<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#plus'}></use>
			</svg>

			<span className="add-item-link__span">{props.children}</span>
		</Link>
	);
};

PlusIconLink.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};
