import React from 'react';

import './style.scss';

const TextFilter = ({ text, img, className, isActive, onClickHandler, id }) => {
	const archiveFilterHandler = e => {
		e.preventDefault();
		if (id === 'ARCHIVE') onClickHandler('PUBLIC');
		else if (id === 'PUBLIC') onClickHandler('ARCHIVE');
		else if (id === 'reset') onClickHandler('PUBLIC');
	};

	return (
		<span
			className={[
				`${isActive === true ? 'text-filter active' : 'text-filter'}`,
				className,
			].join(' ')}
			onClick={archiveFilterHandler}
			id={id}
		>
			<svg role="img">
				<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + `/img/sprite.svg#${img}`}></use>
			</svg>

			<i>{text}</i>
		</span>
	);
};

export default TextFilter;
