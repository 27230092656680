import React, { PureComponent } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/messaging';

import { addToken } from 'rest/push';

import './style.scss';

export class PushPermission extends PureComponent {
	state = {
		isView: false,
	};

	render() {
		if ('serviceWorker' in navigator && 'Notification' in window) {
			if (needToShow()) {
				const { isView } = this.state;
				return (
					<div className={`push-permission ${isView && 'push-permission_show'}`}>
						<div className="push-permission__body">
							<img
								className="push-permission__icon"
								src={`${process.env.REACT_APP_PUBLIC_URL}/img/push.png`}
								alt=""
							/>
							<div className="push-permission__container">
								<h3 className="push-permission__title">Только важные новости</h3>
								<p className="push-permission__text">
									Узнавайте первыми главные события дня. <br />
									Получайте уведомления «МаксиПортала»
								</p>
								<form className="push-permission__form">
									<button
										className="push-permission__btn push-permission__btn_reject"
										onClick={rejectHanlder}
									>
										Не надо
									</button>
									<button
										className="push-permission__btn push-permission__btn_resolve"
										onClick={resolveHandler}
									>
										Хочу
									</button>
								</form>
							</div>
						</div>
					</div>
				);
			} else return null;
		} else return null;
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				isView: true,
			});
		}, 5000);
		if (
			'serviceWorker' in navigator &&
			'Notification' in window &&
			Notification.permission === 'granted' &&
			firebase.messaging.isSupported()
		) {
			window.navigator.serviceWorker.ready
				.then(registration => {
					const messaging = firebase.messaging();
					messaging.usePublicVapidKey(
						'BEBjprXmHXR9ma9B2nDpGnYdy5ed_Ni0ui1nD_sISa4L5fhQbLa2B8lEvG36BvF2w5Ys_bN1S5Nt_mEmm4a2vB0'
					);
					messaging.useServiceWorker(registration);
					messaging
						.getToken()
						.then(token => {
							if (token) {
								console.log('Device token received');
								addToken(token);
							} else {
								console.log('Device token not received');
							}
						})
						.catch(err => {
							console.log('Device token not received, error:', err);
						});
				})
				.catch(err => {
					console.log(`serviceWorker is not ready`, err);
				});
		}
	}
}

const resolveHandler = e => {
	e.preventDefault();
	classRemoveHelper();
	if (firebase.messaging.isSupported()) {
		const messaging = firebase.messaging();
		const sendTokenToServer = permission => {
			if (permission === 'granted') {
				messaging
					.getToken()
					.then(token => {
						if (token) {
							localStorage.removeItem('push');
							console.log('Device token received');
							addToken(token);
						} else {
							console.log('Device token not received');
						}
					})
					.catch(err => {
						console.log('Device token not received, error:', err);
					});
			}
		};

		window.navigator.serviceWorker.ready.then(registration => {
			messaging.usePublicVapidKey(
				'BEBjprXmHXR9ma9B2nDpGnYdy5ed_Ni0ui1nD_sISa4L5fhQbLa2B8lEvG36BvF2w5Ys_bN1S5Nt_mEmm4a2vB0'
			);
			messaging.useServiceWorker(registration);
			try {
				Notification.requestPermission().then(permission => {
					return sendTokenToServer(permission);
				});
			} catch (error) {
				if (error instanceof TypeError) {
					Notification.requestPermission(sendTokenToServer);
				} else {
					console.log('Device token not received, error:', error);
				}
			}
		});
	}
};

const rejectHanlder = e => {
	e.preventDefault();
	localStorage.setItem('push', Date.now());
	classRemoveHelper();
};

const needToShow = () => {
	if (Notification.permission === 'default') {
		const rejectDate = localStorage.getItem('push');
		if (rejectDate) {
			const MAX_AGE = 1209600033.12;
			const dateDiff = Date.now() - +rejectDate;
			return dateDiff > MAX_AGE;
		} else {
			return true;
		}
	} else return false;
};

const classRemoveHelper = () => {
	const pushPermission = document.querySelector('.push-permission');
	if (pushPermission) {
		pushPermission.classList.remove('push-permission_show');
	}
};
