import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FinderFieldMultiple } from 'components/finderFieldMultiple';
import { FormLabel } from 'core/components';
import {
	updateSurveyDepartmentsAction,
	updateSurveyPositionsAction,
	updateSurveyUsersAction,
} from 'action/survey';
import { ISurvey, IUser } from '../types';
import { ImportFromExcel } from 'shared/ui/ImportFromExcel';

interface ISelector {
	survey: ISurvey;
}

interface IProps {
	fixViewComponent: () => void;
}

export const SurveyView: FC<IProps> = ({ fixViewComponent }) => {
	const dispatch = useDispatch();
	const { forUsers, forDepartments, forPositions } = useSelector((state: ISelector) => ({
		forUsers: state.survey.forUsers,
		forDepartments: state.survey.forDepartments,
		forPositions: state.survey.forPositions,
	}));

	const [users, setUsers] = useState<IUser[]>();
	const [departments, setDepartments] = useState<IUser[]>();
	const [positions, setPositions] = useState<IUser[]>();

	useEffect(() => {
		setUsers(forUsers);
	}, [forUsers]);

	useEffect(() => {
		setDepartments(forDepartments);
	}, [forDepartments]);

	useEffect(() => {
		setPositions(forPositions);
	}, [forPositions]);

	const selectUsers = useCallback(
		(list: { id: number }[]) => {
			dispatch(updateSurveyUsersAction(list));
		},
		[dispatch]
	);

	const selectDepartments = useCallback(
		(list: { id: number }[]) => {
			dispatch(updateSurveyDepartmentsAction(list));
		},
		[dispatch]
	);

	const selectPosition = useCallback(
		(list: { id: number }[]) => {
			dispatch(updateSurveyPositionsAction(list));
		},
		[dispatch]
	);

	const getUsersFromExcel = useCallback(
		(users: IUser[]) => {
			dispatch(updateSurveyUsersAction(users));
			fixViewComponent();
		},
		[dispatch, fixViewComponent]
	);

	return (
		<div className="survey-view">
			<div className="survey-view__wrap" id="create-survey-departments-block">
				{/* @ts-ignore */}
				<FormLabel children="Подразделение" />

				<FinderFieldMultiple
					id="create-survey-departments"
					name="departments"
					searchType="DEPARTMENT"
					placeholder="Выберите подразделение"
					onSelected={selectDepartments}
					defaultOptions={departments}
				/>
			</div>

			<div className="survey-view__wrap" id="create-survey-position-block">
				{/* @ts-ignore */}
				<FormLabel children="Должность" />

				<FinderFieldMultiple
					id="create-survey-position"
					name="position"
					searchType="USER_POSITION"
					placeholder="Выберите должность"
					onSelected={selectPosition}
					defaultOptions={positions}
					limit=""
				/>
			</div>

			<div className="survey-view__wrap" id="create-survey-users-block">
				{/* @ts-ignore */}
				<FormLabel children="Пользователь" />

				<FinderFieldMultiple
					id="create-survey-users"
					name="users"
					searchType="USER"
					placeholder="Выберите пользователя"
					onSelected={selectUsers}
					defaultOptions={users}
				/>
			</div>

			<ImportFromExcel
				id="create-survey-import-from-excel"
				getUsers={getUsersFromExcel}
				label="Загрузить список участников опроса из Excel"
			/>
		</div>
	);
};
