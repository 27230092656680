import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';
import { useForm } from 'react-hook-form';

import { setCreateVacancy } from 'action/vacancies';

import { InputText, Select, TextField, Button, FormLabel, TextAreaHook } from 'core/components';

import { PageTitle } from '../../atoms';
import { FormInputBlock } from '../../molecules';
import { FinderField } from 'components/finderField/feature/Field';

export const VacancyCreateForm = withRouter(function VacancyCreateForm(props) {
	const navigate = useNavigate();
	const [cityId, setCityId] = useState(1);
	const [salary, setSalary] = useState('noSalary');
	const [state, setState] = useState('PUBLIC');

	const { handleSubmit, register, errors, setValue } = useForm();

	const cities = useSelector(state => state.cities);
	const dispatch = useDispatch();

	useEffect(() => {
		register('departmentId', { required: true });
	}, [register]);

	const onSubmit = data => {
		let { wage0, wage1, wage2 } = data;

		let vacancyParams = {
			...data,
			wage: wage2 !== '' ? wage1 : wage0,
			wage2: wage2,
		};

		dispatch(setCreateVacancy(vacancyParams, navigate));
	};

	let cityOptions = [];
	cities.forEach(item => {
		cityOptions.push({ value: item.id, label: item.name });
	});
	cityOptions.sort(function (a, b) {
		return a.value - b.value;
	});

	const onCityChange = cityId => {
		setCityId(cityId);
	};

	const validate = value => {
		return value.trim().length >= 0;
	};

	const salaryChange = e => {
		setSalary(e.currentTarget.value);
		let wages = document.querySelectorAll('.wages');
		wages.forEach.call(wages, function (item) {
			item.value = '';
		});
	};

	const archiveVacancyHandler = e => {
		e.preventDefault();
		if (state === 'PUBLIC') {
			setState('ARCHIVE');
		} else {
			setState('PUBLIC');
		}
	};

	const handleName = name => {
		setValue('contactId', undefined);
		setValue('contactName', name);
	};

	const handleFinder = ({ id, name }) => {
		setValue('contactId', id);
		setValue('contactName', name);
	};

	const addDepartment = department => {
		setValue('departmentId', department.id);
	};
	const clearDepartment = () => {
		setValue('departmentId', null);
	};

	return (
		<form className="vacancies-create__body" onSubmit={handleSubmit(onSubmit)}>
			<input type="hidden" name="contactId" ref={register({ required: true })} />

			<input type="hidden" name="cityId" value={cityId} ref={register({ required: true })} />

			<input type="hidden" name="state" value={state} ref={register({ required: true })} />

			<FormInputBlock>
				<TextField
					error={!!errors.position}
					label="Название вакансии"
					name="position"
					placeholder="Введите текст"
					inputRef={register({
						maxLength: 255,
						required: true,
					})}
					autoComplete="off"
					fullWidth="true"
				/>
			</FormInputBlock>

			<FormInputBlock>
				<FormLabel>Город</FormLabel>

				<Select options={cityOptions} defaultValue={cityId} onChange={onCityChange} />
			</FormInputBlock>

			<FormInputBlock>
				<FinderField
					error={!!errors.departmentId}
					label="Подразделение"
					searchType="DEPARTMENT"
					placeholder="Выберите подразделение"
					onSelected={addDepartment}
					onChange={clearDepartment}
				/>
			</FormInputBlock>

			<FormInputBlock>
				<FormLabel>Тип должности</FormLabel>
				<div className="radio-group">
					<div className="radio-group__name">
						<input
							defaultChecked
							name="level"
							type="radio"
							id="chief"
							value="CHIEF"
							ref={register({ required: true })}
						/>
						<label htmlFor="chief" className="custom-radio">
							Менеджмент
						</label>
					</div>

					<div className="radio-group__name">
						<input
							name="level"
							type="radio"
							id="specialist"
							value="SPECIALIST"
							ref={register({ required: true })}
						/>
						<label htmlFor="specialist" className="custom-radio">
							Специалист
						</label>
					</div>

					<div className="radio-group__name">
						<input
							name="level"
							type="radio"
							id="executor"
							value="EXECUTOR"
							ref={register({ required: true })}
						/>
						<label htmlFor="executor" className="custom-radio">
							Рабочие профессии
						</label>
					</div>
				</div>
			</FormInputBlock>

			<FormInputBlock>
				<FormLabel>Обязанности</FormLabel>
				<TextAreaHook
					name="responsibility"
					placeholder="Введите текст"
					valid={!errors.responsibility}
					ref={register({
						validate,
						maxLength: 4000,
						required: true,
					})}
				/>
			</FormInputBlock>

			<FormInputBlock>
				<FormLabel>Требования</FormLabel>
				<TextAreaHook
					name="requirements"
					placeholder="Введите текст"
					valid={!errors.requirements}
					ref={register({
						validate,
						maxLength: 4000,
						required: true,
					})}
				/>
			</FormInputBlock>

			<FormInputBlock>
				<FormLabel>Условия</FormLabel>
				<TextAreaHook
					name="conditions"
					placeholder="Введите текст"
					valid={!errors.conditions}
					ref={register({
						validate,
						maxLength: 4000,
					})}
				/>
			</FormInputBlock>

			<FormInputBlock>
				<FormLabel>Заработная плата</FormLabel>
				<div className="radio-group">
					<div className="radio-group__name">
						<input
							name="salary"
							type="radio"
							id="salaryValue"
							value="salaryValue"
							onClick={salaryChange}
						/>
						<label htmlFor="salaryValue" className="custom-radio">
							<div className="custom-radio__block">
								<InputText
									id="wage0"
									name="wage0"
									className="wages"
									valid={!errors.wage0}
									ref={register({
										maxLength: 255,
									})}
									type="number"
									inputMode="number"
									placeholder="40 000"
									disabled={salary !== 'salaryValue' ? true : false}
								/>
							</div>
						</label>
					</div>

					<div className="radio-group__name">
						<input
							name="salary"
							type="radio"
							id="salaryGap"
							value="salaryGap"
							onClick={salaryChange}
						/>
						<label htmlFor="salaryGap" className="custom-radio">
							<div className="custom-radio__block">
								<span>от</span>
								<InputText
									id="wage1"
									name="wage1"
									className="wages"
									valid={!errors.wage1}
									ref={register({
										maxLength: 255,
									})}
									type="number"
									inputMode="number"
									placeholder="40 000"
									disabled={salary !== 'salaryGap' ? true : false}
								/>
								<span>до</span>
								<InputText
									id="wage2"
									name="wage2"
									className="wages"
									valid={!errors.wage2}
									ref={register({
										maxLength: 255,
									})}
									type="number"
									inputMode="number"
									placeholder="60 000"
									disabled={salary !== 'salaryGap' ? true : false}
								/>
							</div>
						</label>
					</div>

					<div className="radio-group__name">
						<input
							defaultChecked={salary === 'noSalary'}
							name="salary"
							type="radio"
							id="noSalary"
							value="noSalary"
							onClick={salaryChange}
						/>
						<label htmlFor="noSalary" className="custom-radio">
							По результатам собеседования
						</label>
					</div>
				</div>
			</FormInputBlock>

			<FormInputBlock>
				<FormLabel>Состояние вакансии</FormLabel>
				<div className="body__hot-vac">
					<div className="cbx_body" onClick={archiveVacancyHandler}>
						<span className={state === 'ARCHIVE' ? 'cbx active' : 'cbx'}>
							<svg role="img">
								<use
									xlinkHref={
										process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#ok'
									}
								></use>
							</svg>
						</span>

						<span>Добавить в архив</span>
					</div>
				</div>
			</FormInputBlock>

			<div className="body__contacts">
				<PageTitle title="Контакты" />

				<FinderField
					label="ФИО для визитки"
					placeholder="Выберите менеджера вакансии"
					onChange={handleName}
					onSelected={handleFinder}
					name="user"
					searchType="USER"
					error={!!errors.contactId}
				/>
			</div>

			<Button
				type="primary"
				typeButton="submit"
				text={state === 'PUBLIC' ? 'Опубликовать' : 'Сохранить'}
				handler={null}
				image={null}
			/>
		</form>
	);
});
