import React, { FC } from 'react';
import { TextArea } from '@maxi-innovation/ui-kit-frontend';

import { FormInputBlock } from 'core/components';

interface IProps {
	name?: string;
	label: string;
	placeholder: string;
	desc?: string;
	defaultValue?: string;
	maxLength?: number;
	onChange?: (value: string) => void;
	error?: boolean;
	disabled?: boolean;
	blockId?: string;
}

export const SurveyTextField: FC<IProps> = ({
	name,
	label,
	placeholder,
	desc,
	defaultValue,
	maxLength,
	onChange,
	error,
	disabled,
	blockId,
}) => {
	const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		let { value } = event.currentTarget;

		if (onChange) onChange(value);
	};

	return (
		<FormInputBlock id={blockId}>
			<TextArea
				label={label}
				error={error}
				description={desc}
				defaultValue={defaultValue}
				name={name ? name : 'field'}
				maxLength={maxLength}
				disabled={disabled}
				onChange={onChangeHandler}
				placeholder={placeholder}
			/>
		</FormInputBlock>
	);
};
