import React from 'react';

export const Italic = () => {
	return (
		<svg
			width="143.1"
			height="203"
			viewBox="0 0 143.1 203"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M58.6 0l-9.1 36h20.5l-40.6 130h-20.3l-9.1 37h86.6l9.1-37h-21.8l40.4-130h19.6l9.2-36z" />
		</svg>
	);
};
