import React from 'react';
import { connect } from 'react-redux';

import { updateFilterParams } from 'action/vacancies';

import './style.scss';

export const Pagination = props => {
	return (
		<div className="vacancies-list__pagination">
			{props.vacanciesListParams !== null && arrowsRenderHelper(props)}
		</div>
	);
};

const arrowsRenderHelper = props => {
	const changePageByArrowHandler = e => {
		e.preventDefault();
		let type = e.currentTarget.dataset.type;
		let id = e.currentTarget.dataset.id;
		if (type === 'next') {
			props.updateFilterParams({ page: +id + 1 });
			window.scrollTo(0, 0);
		} else {
			props.updateFilterParams({ page: +id - 1 });
			window.scrollTo(0, 0);
		}
	};

	if (+props.vacanciesListParams.page === 0 && props.vacanciesList.hasNextPage) {
		return (
			<div className="pagination__block">
				<span
					className="pagination__arrow pagination__next"
					onClick={changePageByArrowHandler}
					data-type="next"
					data-id={props.vacanciesListParams.page}
				>
					<svg role="img">
						<use
							xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#arrow'}
						></use>
					</svg>
				</span>
			</div>
		);
	} else if (+props.vacanciesListParams.page !== 0 && props.vacanciesList.hasNextPage) {
		return (
			<div className="pagination__block">
				<span
					className="pagination__arrow pagination__prev"
					onClick={changePageByArrowHandler}
					data-type="prev"
					data-id={props.vacanciesListParams.page}
				>
					<svg role="img">
						<use
							xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#arrow'}
						></use>
					</svg>
				</span>

				<span
					className="pagination__arrow pagination__next"
					onClick={changePageByArrowHandler}
					data-type="next"
					data-id={props.vacanciesListParams.page}
				>
					<svg role="img">
						<use
							xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#arrow'}
						></use>
					</svg>
				</span>
			</div>
		);
	} else if (+props.vacanciesListParams.page !== 0 && !props.vacanciesList.hasNextPage) {
		return (
			<div className="pagination__block">
				<span
					className="pagination__arrow pagination__prev"
					onClick={changePageByArrowHandler}
					data-type="prev"
					data-id={props.vacanciesListParams.page}
				>
					<svg role="img">
						<use
							xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#arrow'}
						></use>
					</svg>
				</span>
			</div>
		);
	} else {
		return null;
	}
};

const mapStateToProps = state => {
	return {
		vacanciesList: state.vacancies.vacanciesList,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateFilterParams: params => {
			dispatch(updateFilterParams(params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
