import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URL } from 'constant';

import { Avatar } from 'core/components';
import { notificationViewAction } from 'action/notification';
import {
	UserAddon,
	PostAddon,
	GroupAddon,
	ClaimAddon,
	CongratulationAddon,
	ThanksAddon,
	ActivityAddon,
	AchievementAddon,
	DevelopmentGoalAddon,
	SurveyAddon,
	MaxiClubAddon,
	ActivityMemberAddon,
} from './textLinks';

import './style.scss';

const mapper = {
	user: UserAddon,
	post: PostAddon,
	group: GroupAddon,
	claim: ClaimAddon,
	createClaim: MaxiClubAddon,
	congratulation: CongratulationAddon,
	thanks: ThanksAddon,
	activity: ActivityAddon,
	activity_member: ActivityMemberAddon,
	achievement: AchievementAddon,
	goal: DevelopmentGoalAddon,
	survey: SurveyAddon,
};

const WithProps = ({ content, ...props }) => {
	const children = content.map((entry, index) => {
		if (entry.startsWith('{') && entry.endsWith('}')) {
			const key = entry.slice(1, -1);
			const Component = mapper[key];

			return <Component {...props} key={index} />;
		} else {
			return entry;
		}
	});

	return <>{children}</>;
};

const NotificationView = ({ notification }) => {
	const dispatch = useDispatch();
	const { shortUserInfo } = useSelector(state => ({
		shortUserInfo: state.shortUserInfo,
	}));
	const [content, setContent] = useState([]);

	useEffect(() => {
		setContent(notification.text.split(/({.*?})/g));
	}, [notification.text]);

	const notificationsShowListener = event => {
		if (!notification.showed) {
			event.preventDefault();
			dispatch(notificationViewAction(notification.id, event.target?.href));
		}
	};

	return (
		<div className="user-notification" onClick={notificationsShowListener}>
			<Avatar
				image={
					notification?.variables?.user?.avatarFilePath
						? URL + notification?.variables?.user?.avatarFilePath
						: process.env.REACT_APP_PUBLIC_URL + '/img/ntf.png'
				}
				type="user"
				size="small"
			/>

			<div className="user-notification__wrap">
				<div
					className={
						notification.showed
							? 'user-notification__text showed'
							: 'user-notification__text'
					}
				>
					<WithProps
						content={content}
						variables={notification.variables}
						type={notification.type}
						shortUserInfo={shortUserInfo}
					/>
				</div>
				<div className="user-notification__date">{notification.createDate}</div>
			</div>
		</div>
	);
};

export default NotificationView;
