import React from 'react';

export const Maximazie = ({ onClick }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path d="M17 20h-3c-.6 0-1-.4-1-1s.4-1 1-1h3c.6 0 1-.4 1-1v-3c0-.6.4-1 1-1s1 .4 1 1v3c0 1.7-1.3 3-3 3zm-11 0h-3c-1.7 0-3-1.3-3-3v-3c0-.6.4-1 1-1s1 .4 1 1v3c0 .6.4 1 1 1h3c.6 0 1 .4 1 1s-.4 1-1 1zm13-13c-.6 0-1-.4-1-1v-3c0-.6-.4-1-1-1h-3c-.6 0-1-.4-1-1s.4-1 1-1h3c1.7 0 3 1.3 3 3v3c0 .6-.4 1-1 1zm-18 0c-.6 0-1-.4-1-1v-3c0-1.7 1.3-3 3-3h3c.6 0 1 .4 1 1s-.4 1-1 1h-3c-.6 0-1 .4-1 1v3c0 .6-.4 1-1 1z" />
		</svg>
	);
};
