import React from 'react';
import { Link } from 'react-router-dom';
import { clearStoreSearchResult, textColor } from '../../utils';
import './style.scss';
import ReactGA from 'react-ga4';

const clickShortFaqItemHandler = closeHandle => {
	if (closeHandle) closeHandle();
	ReactGA.event({
		category: 'Переходы по результатам поиска',
		action: 'Переход в "FAQ"',
		label: 'Открытия детального просмотра FAQ',
	});

	clearStoreSearchResult();
};

const ShortFaqItem = ({ id, question, categoryId, fillText, indexName, closeHandle }) => {
	return (
		<Link
			className="short-faq-item"
			to={`/knowledge/faq/${categoryId}#question-${id}&fromIndex-${indexName}`}
			onClick={() => clickShortFaqItemHandler(closeHandle)}
		>
			{textColor(question, fillText)}
		</Link>
	);
};

export default ShortFaqItem;
