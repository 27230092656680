import React from 'react';

export const Underline = () => {
	return (
		<svg
			width="476.8"
			height="512"
			viewBox="0 0 476.8 512"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M304.7 0v242c0 22.9-5.2 39.8-16.6 50.7-11.5 10.9-27.5 16.3-48.8 16.3-21.4 0-38-5.5-49.7-16.4-11.7-11-17.6-27.8-17.6-50.6v-242h-87v242c0 42.3 14.6 75.1 42.8 98.3 28.2 23.3 65.5 34.9 111.6 34.9 45.5 0 82.3-11.6 110.4-34.9 28.1-23.3 42.1-56 42.1-98.3v-242h-87.2zM0 443.6h476.8v68.4h-476.8z" />
		</svg>
	);
};
