import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { URL } from 'constant';
import { Eye } from 'react-feather';

import './style.scss';

import { Avatar, ScrollToTopOnMount, Loader, Roles } from 'core/components';
import { hasRole } from 'core/utils';
import { BackArrow } from '../../atoms';

const VacancyView = ({ info, arrayRoles, vacancyId, vacanciesListParams }) => {
	const location = useLocation();
	if (info !== null) {
		const { state: lstate } = location;

		return (
			<div className="vacancies-view__item">
				<ScrollToTopOnMount />

				<BackArrow
					link={
						vacanciesListParams !== null
							? `/vacancies?cityId=${vacanciesListParams.cityId}${
									!vacanciesListParams.sector
										? ''
										: `&sector=${vacanciesListParams.sector}`
							  }${
									!vacanciesListParams.level
										? ''
										: `&level=${vacanciesListParams.level}`
							  }&state=${vacanciesListParams.state}&page=${
									vacanciesListParams.page
							  }&onlyMy=${
									vacanciesListParams?.onlyMy
										? vacanciesListParams.onlyMy
										: 'false'
							  }`
							: `/vacancies?cityId=1&state=PUBLIC&page=0&onlyMy=false`
					}
				/>

				<div className="item__header">
					<div className="header-text">
						<div className="header-title">
							<p>{info.position}</p>
						</div>
						{info.department && (
							<span className="header-salary">{info.department.name}</span>
						)}
						{salaryRenderHelper(info.wage, info.wage2)}
					</div>

					{arrayRoles && (
						<Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_VACANCY_CLAIM')}>
							<Link
								to={{
									pathname: `/vacancies/${vacancyId}/edit`,
									state: {
										...lstate,
										back: lstate && lstate.back ? lstate.back - 1 : -1,
									},
								}}
								className="header-edit"
							>
								<svg role="img">
									<use
										xlinkHref={
											process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#pen'
										}
									></use>
								</svg>
							</Link>
						</Roles>
					)}
				</div>

				<div className="item__body">
					{info.requirements !== '' && (
						<div className="body-block">
							<p className="body-block__title">Обязанности</p>
							<p className="body-block__description">{info.responsibility.trim()}</p>
						</div>
					)}

					{info.responsibility !== '' && (
						<div className="body-block">
							<p className="body-block__title">Требования</p>
							<p className="body-block__description">{info.requirements.trim()}</p>
						</div>
					)}

					{info.conditions !== '' && (
						<div className="body-block">
							<p className="body-block__title">Условия</p>
							<p className="body-block__description">{info.conditions.trim()}</p>
						</div>
					)}

					<div className="body-lower">
						<span className="body-lower__date">{info.publishDate}</span>

						{arrayRoles && (
							<Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_VACANCY_CLAIM')}>
								<div className="body-lower__view">
									<Eye />

									<i>{info.viewsCount}</i>
								</div>
							</Roles>
						)}
					</div>
				</div>

				<div className="item__footer">
					<p className="footer-title">Контакты</p>

					<div className="footer-contact">
						<Link to={`/user/${info.contact.id}`}>
							<Avatar
								type="user"
								size="small"
								image={
									info.contact.avatarFile !== null
										? URL + info.contact.avatarFile.filePath
										: null
								}
							/>
						</Link>

						<div className="footer-contact__info">
							<Link
								to={`/user/${info.contact.id}`}
								className="name"
							>{`${info.contact.surname} ${info.contact.name}`}</Link>
							<span className="position">{info.contact.position}</span>
							{info.contact.workPhone !== '' && info.contact.workPhone !== null && (
								<p className="link">
									<span>Телефон:</span>
									<a href={`tel:${info.contact.workPhone}`}>
										{info.contact.workPhone}
									</a>
								</p>
							)}

							{info.contact.email !== '' && info.contact.email !== null && (
								<p className="link">
									<span>E-mail:</span>
									<a href={`mailto:${info.contact.email}`}>
										{info.contact.email}
									</a>
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return <Loader />;
	}
};

export const salaryRenderHelper = (wage, wage2) => {
	if (wage === null || wage === '') {
		return <span className="header-salary">з/п по результатам собеседования</span>;
	} else if (wage !== null && wage2 !== null && wage !== '' && wage2 !== '') {
		return (
			<span className="header-salary">
				от {wage}
				<svg role="img">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#vacancies-rouble'
						}
					></use>
				</svg>
				до {wage2}
				<svg role="img">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#vacancies-rouble'
						}
					></use>
				</svg>
			</span>
		);
	} else {
		return (
			<span className="header-salary">
				{wage}{' '}
				<svg role="img">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#vacancies-rouble'
						}
					></use>
				</svg>
			</span>
		);
	}
};

export default VacancyView;
