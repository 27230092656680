import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

const MoreBtn = ({ title, link }) => {
	return (
		<Link to={link} className="more-btn">
			<span className="more-btn__text">{title}</span>

			<svg role="img" className="more-btn__img">
				<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
			</svg>
		</Link>
	);
};

export default MoreBtn;
