import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Navigate } from 'react-router-dom';
import Helmet from 'react-helmet';

import { PulseSurveyTable, SurveySidebar } from 'feature/survey';
import { Icon } from '@maxi-innovation/ui-kit-frontend';
import { Col, Grid } from 'core/components';

import './style.scss';

interface ISelector {
	shortUserInfo: {
		roles: string[];
	};
}

export const PulseSurveyList = () => {
	const { roles } = useSelector((state: ISelector) => ({
		roles: state.shortUserInfo.roles,
	}));

	return roles ? (
		roles.includes('ROLE_PULSE_SURVEY') ? (
			<section className="section-survey">
				<Helmet title="Опрос" />

				<Grid>
					<Col lg={2}>
						<SurveySidebar />
					</Col>

					<Col lg={10}>
						<div className="pulse-survey-list">
							<div className="pulse-survey-list__header">
								<p className="pulse-survey-list__title">Пульс-опросы</p>

								<NavLink
									to="/services/pulse-survey/create"
									className="create-pulse-survey-link"
									id="create-pulse-survey-link"
								>
									<Icon
										iconName="Plus"
										className="create-pulse-survey-link__icon"
										color="mc74"
										w={15}
										h={15}
									/>
									<span className="create-pulse-survey-link__text">
										Создать пульс-опрос
									</span>
								</NavLink>
							</div>

							<PulseSurveyTable />
						</div>
					</Col>
				</Grid>
			</section>
		) : (
			<Navigate to="/forbidden" />
		)
	) : null;
};
