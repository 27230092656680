import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TextField, Button, TypeButton, Typography } from '@maxi-innovation/ui-kit-frontend';

import { useFormState, useRecoverCode, useRecoverPhone } from 'feature/auth-recover/model';
import { getRestPhone } from 'plugins/getRestPhone';
import { Countdown } from 'shared/ui/Countdown';

const validationSchema = yup.object().shape({
	code: yup.string().required('Введите код'),
});

export const CodeForm = () => {
	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const [theEnd, setEnd] = useState(false);
	const { setPhoneView, phone, deadline } = useFormState();
	const { isLoading, errorMessage, sendCode } = useRecoverCode();
	const {
		isLoading: isLoadingGetCode,
		errorMessage: errorMessageGetCode,
		getCode,
	} = useRecoverPhone();

	useEffect(() => setEnd(false), [deadline]);

	const onLinkClick = e => {
		e.preventDefault();
		setPhoneView();
	};

	const onGetCodeClick = e => {
		e.preventDefault();
		const clearPhone = getRestPhone(phone);
		getCode(clearPhone);
	};

	const onSubmit = handleSubmit(data => {
		sendCode(data.code);
	});

	return (
		<form className="auth-form" onSubmit={onSubmit}>
			<svg role="img" className="auth-logo">
				<use
					xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#entry-red-logo'}
				/>
			</svg>
			{!!errorMessage && (
				<span className="auth-form-error-message" id="auth-recover-code-error-message">
					{errorMessage}
				</span>
			)}
			{!!errorMessageGetCode && (
				<span
					className="auth-form-error-message"
					id="auth-recover-code-error-message-get-code"
				>
					{errorMessageGetCode}
				</span>
			)}
			<div className="auth-control-block">
				<Typography as="span" variant="bodyRegular">
					Код подтверждения отправлен SMS-сообщением на Ваш номер телефона
					<br />
					<Typography as="span" variant="bodyRegular" color="mc74">
						{phone}
					</Typography>
				</Typography>
				<TextField
					variant="default"
					autoComplete="off"
					name="code"
					id="auth-recover-control-code"
					ref={register}
					placeholder="Введите код"
					error={!!errors?.code}
					errorMessage={`${errors?.code?.message}`}
				/>
			</div>
			<Button
				variant={TypeButton.PRIMARY}
				type="submit"
				className="auth-action-btn"
				id="auth-recover-code-btn"
				disabled={isLoading || isLoadingGetCode}
				loading={isLoading || isLoadingGetCode}
			>
				Отправить
			</Button>
			<div className="auth-code-form-no-message">
				<span className="auth-code-form-no-message__title">Не пришло сообщение?</span>
				<Button
					className="auth-code-form-no-message__btn"
					id="auth-recover-code-sms"
					variant={TypeButton.LINKCAPS}
					transformtext="none"
					onClick={onGetCodeClick}
					disabled={isLoading || isLoadingGetCode || !theEnd}
				>
					Получить новый код{' '}
					{!theEnd && (
						<>
							через <Countdown deadline={deadline} setEnd={setEnd} />
						</>
					)}
				</Button>
			</div>
			<div className="auth-form-footer">
				<a
					href="/"
					onClick={onLinkClick}
					id="auth-recover-code-back-link"
					className="entryPage__link red-text"
				>
					Вернуться
				</a>
			</div>
		</form>
	);
};
