import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';

import { Eye } from 'react-feather';
import { DESCRIPTION_COUNT } from 'constant';

import { Roles } from 'core/components';
import { hasRole } from 'core/utils';

import './style.scss';

import { MoreBtn } from '../../atoms';

const VacancyItem = item => {
	const { state: lstate } = item.location;
	return (
		<div className={item.hot ? 'vacancy-item hot-vacancy' : 'vacancy-item'}>
			<div className="vacancy-item__header">
				<div className="header__title">
					<Link
						to={{
							pathname:
								item.state === 'PUBLIC'
									? `/vacancies/${item.id}`
									: `/vacancies/${item.id}/edit`,
							state: {
								...lstate,
								back: lstate && lstate.back ? lstate.back - 1 : -1,
							},
						}}
					>
						{item.position}
					</Link>
					{item.department && (
						<span className="header-salary">{item.department.name}</span>
					)}
					{salaryRenderHelper(item.wage, item.wage2)}
				</div>
			</div>

			<span className="vacancy-item__description">
				{descriptionTrimmer(item.responsibility)}
			</span>

			<div className="vacancy-item__footer">
				<div className="footer__data">
					<span className="footer__date">{item.publishDate}</span>

					{item.arrayRoles && (
						<Roles isView={hasRole(item.arrayRoles, 'ROLE_EDIT_VACANCY_CLAIM')}>
							<div className="footer__view">
								<Eye />

								<i>{item.viewsCount}</i>
							</div>
						</Roles>
					)}
				</div>

				<MoreBtn
					title="Подробнее"
					link={
						item.state === 'PUBLIC'
							? `/vacancies/${item.id}`
							: `/vacancies/${item.id}/edit`
					}
				/>
			</div>
		</div>
	);
};

export const salaryRenderHelper = (wage, wage2) => {
	if (wage === null || wage === '') {
		return <span>з/п по результатам собеседования</span>;
	} else if (wage !== null && wage2 !== null && wage !== '' && wage2 !== '') {
		return (
			<span>
				от {wage}
				<svg role="img">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#vacancies-rouble'
						}
					></use>
				</svg>
				до {wage2}
				<svg role="img">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#vacancies-rouble'
						}
					></use>
				</svg>
			</span>
		);
	} else {
		return (
			<span>
				{wage}{' '}
				<svg role="img">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#vacancies-rouble'
						}
					></use>
				</svg>
			</span>
		);
	}
};

export const descriptionTrimmer = description => {
	if (description.length >= DESCRIPTION_COUNT) {
		return description
			.slice(0, DESCRIPTION_COUNT)
			.slice(0, description.slice(0, DESCRIPTION_COUNT).lastIndexOf(' '));
	} else {
		return description;
	}
};

export default withRouter(VacancyItem);
