import React from 'react';

export const Pause = ({ onClick }) => {
	return (
		<svg
			width="14"
			height="18"
			viewBox="0 0 14 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path d="M5 18h-4c-.6 0-1-.4-1-1v-16c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1zm-3-2h2v-14h-2v14zM13 18h-4c-.6 0-1-.4-1-1v-16c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1zm-3-2h2v-14h-2v14z" />
		</svg>
	);
};
