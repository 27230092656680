import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';

import './style.scss';

export const SectionChild = withRouter(({ to, children, location }) => {
	const { state } = location;
	return (
		<div className="section-child-wrapper">
			<NavLink
				to={{
					pathname: to,
					state: {
						...state,
						back: -1,
					},
				}}
				className="section-child"
			>
				<svg role="img" className="section-child__svg">
					<use
						xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#folder'}
					></use>
				</svg>

				<span className="section-child__text">{children}</span>
			</NavLink>
		</div>
	);
});

SectionChild.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired,
};
