import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';

import './style.scss';

export const MoreBtn = withRouter(({ title = 'Подробнее', link, location }) => {
	return (
		<Link
			to={{
				pathname: link,
				state: {
					...location.state,
					back: -1,
				},
			}}
			className="more-btn"
		>
			<span className="more-btn__text">{title}</span>

			<svg role="img" className="more-btn__img">
				<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
			</svg>
		</Link>
	);
});
