import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { bodyFixPosition, bodyUnfixPosition } from '../../utils';

import './style.scss';

//Компонент отвечает только за работу со скролом
export const Modal = props => {
	useEffect(() => {
		bodyFixPosition();

		return () => {
			bodyUnfixPosition();
		};
	}, []);

	return (
		<div className="Modal-wrapp">
			{props.onClose && (
				<svg role="img" className="wrapp-close">
					<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#cross'} />
				</svg>
			)}
			{props.children}
		</div>
	);
};

Modal.propTypes = {
	onClose: PropTypes.func,
};
