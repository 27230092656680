import { combineReducers } from 'redux';

import ui from './ui';
import shortUserInfo from './shortUserInfo';
import fullUserInfo from './fullUserInfo';
import groupInfo from './groupInfo';
import groupList from './groupList';
import groupPosts from './groupPosts';
import notification from './notification';
import welcomeMessage from './welcomeMessage';
import maxiclub from './maxiclub';
import roles from './roles';
import walkFame from './walkFame';
import albums from './albums';
import competences from './competences';
import stickers from './stickers';
import userNotification from './userNotification';
import commentEditStatus from './commentEditStatus';
import claims from './claims';
import globalSearch from './globalSearch';
import MIForm from '../core/form/reducer';
import activity from './activity';
import stimulation from './stimulation';
import article from './article';
import cities from './cities';
import vacancies from './vacancies';
import thanks from './thanks';
import glossary from './glosary';
import faq from './faq';
import search from './search';
import searchRightsItem from './searchRightsItem';
import feed from './feed';
import guide from './guide';
import documents from './documents';
import groups from './groups';
import rolesByPositions from './rolesByPositions';
import services from './services';
import secretaryToDepartment from './secretaryToDepartment';
import contacts from './contacts';
import postPage from './postPage';
import polls from './polls';
import userRequests from './userRequests';
import trips from './trips';
import passes from './passes';
import options from './options';
import gallery from './gallery';
import favoritesPosts from './favoritesNews';
import mainPagePosts from './mainPageNews';
import congratulationModalState from './congratulation';
import mentoring from './mentoring';
import retailShifts from './retailShifts';
import hotLine from './hotLine';
import corpValuesState from './corporateValues';
import iSpringLink from './iSpring';
import mirapolisLink from './mirapolis';
import achievements from './achievements';
import ltr from './ltr';
import survey from './survey';
import workplace from './workplace';
import inventory from './inventory';
import education from './education';
import messenger from './messenger';
import strategy from './strategy';
import maxiProfessional from './maxiProfessional';
import courier from './courier';
import vacation from './vacation';
import copyPasses from './copyPasses';
import copyStatements from './statements';
import pulseSurvey from './PulseSurvey';
import claimProfile from './claimsProfile';
import wishingBall from './wishingBall';
import repairService from './repairService';

export default combineReducers({
	ui,
	globalSearch,
	shortUserInfo,
	fullUserInfo,
	groupInfo,
	groupList,
	groupPosts,
	notification,
	welcomeMessage,
	maxiclub,
	roles,
	walkFame,
	albums,
	competences,
	stickers,
	userNotification,
	commentEditStatus,
	claims,
	MIForm,
	activity,
	stimulation,
	article,
	cities,
	vacancies,
	thanks,
	glossary,
	guide,
	documents,
	faq,
	search,
	searchRightsItem,
	feed,
	groups,
	rolesByPositions,
	services,
	secretaryToDepartment,
	contacts,
	postPage,
	polls,
	userRequests,
	trips,
	passes,
	options,
	gallery,
	favoritesPosts,
	mainPagePosts,
	congratulationModalState,
	mentoring,
	retailShifts,
	hotLine,
	corpValuesState,
	iSpringLink,
	mirapolisLink,
	achievements,
	ltr,
	survey,
	workplace,
	inventory,
	education,
	messenger,
	strategy,
	maxiProfessional,
	courier,
	vacation,
	copyPasses,
	copyStatements,
	pulseSurvey,
	claimProfile,
	wishingBall,
	repairService,
});
