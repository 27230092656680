import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { URL } from 'constant';

export const GalleryItem = ({ id, item, isOpenGalleryHandler }) => {
	const { idPost } = useParams();
	const { search, pathname } = useLocation();

	return idPost ? (
		<Link
			to={`${pathname}${search}#p${id}_i${item.id}`}
			onClick={isOpenGalleryHandler}
			className="post-image"
		>
			<span className="post-image__wrap">
				<img
					src={`${URL + item.filePath}?size=MEDIUM`}
					alt={item.fullName}
					className="post-image__img"
				/>
			</span>
		</Link>
	) : (
		<Link
			to={`${pathname}${search}#p${id}_i${item.id}`}
			onClick={isOpenGalleryHandler}
			className="post-image"
		>
			<span className="post-image__wrap">
				<img
					src={`${URL + item.filePath}?size=MEDIUM`}
					alt={item.fullName}
					className="post-image__img"
				/>
			</span>
		</Link>
	);
};
