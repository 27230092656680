import React from 'react';

export const Play = ({ onClick }) => {
	return (
		<svg
			width="16"
			height="20"
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path d="M1 20c-.2 0-.3 0-.5-.1-.3-.2-.5-.5-.5-.9v-18c0-.4.2-.7.5-.9.3-.2.7-.2 1 0l14 9c.3.2.5.6.5.9s-.2.7-.5.8l-14 9c-.1.1-.3.2-.5.2zm1-17.2v14.3l11.2-7.1-11.2-7.2z" />
		</svg>
	);
};
