import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

export const CrumbItem = ({ to, children, id }) => {
	if (to) {
		return (
			<div className="crumb-item">
				<Link className="crumb-item__text" to={to} title={children} id={id}>
					{children}
				</Link>

				<svg role="img" className="crumb-item__svg">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#bold-mini-arrow'
						}
					></use>
				</svg>
			</div>
		);
	} else {
		return (
			<div className="crumb-item">
				<span className="crumb-item__text" title={children}>
					{children}
				</span>
			</div>
		);
	}
};
