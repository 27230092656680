import React from 'react';
import { Typography } from '@maxi-innovation/ui-kit-frontend';

interface IProps {
	htmlFor?: string;
	children: JSX.Element | string;
}

export const Label = ({ htmlFor, children }: IProps) => {
	return (
		<label className="my-label" htmlFor={htmlFor}>
			<Typography as="span" variant="bodyRegular" weight="bold">
				{children}
			</Typography>
		</label>
	);
};
