import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Button, TypeButton } from '@maxi-innovation/ui-kit-frontend';

import { Select, Datepicker, FormInputBlock } from 'core/components';
import { Editor } from 'components/editorjsText';

import Notification from 'modules/notification';
import { NotificationHelper } from 'action/notificationHelper';

import { getDocument, updateDocument, deleteDocument } from 'rest/documents';

import { Header } from '../../organisms/header';
import { Wrapp } from '../../atoms/mainWrapp';
import { Text, HeaderWrapp } from '../../atoms/header';

import { ArticleLoader } from '../../organisms/loader';
import { DeleteModal } from '../../organisms/deleteModal';
import { getWikiDocumentsListRest } from 'shared/api/documents';
import { withRouter } from 'hoc/withRouter';

const DocumentEdit = ({ navigate, params }) => {
	const { id, themeId } = useParams();
	const [currentDocument, setCurrentDocument] = useState(null);
	const [name, setName] = useState('');
	const [content, setContent] = useState('');
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [startDate, setStartDate] = useState('');
	const [startDateDefault, setStartDateDefault] = useState('');
	const [startDateValid, setStartDateValid] = useState(true);
	const [endDate, setEndDate] = useState('');
	const [endDateDefault, setEndDateDefault] = useState('');
	const [options, setOptions] = useState([]);
	const [option, setOption] = useState(themeId);

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			const { response, responseCode, errorMessage } = await getWikiDocumentsListRest();

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				setOptions(response.map(item => ({ value: item.id, label: item.title })));
			}
		})();
	}, [dispatch]);

	const nameHandler = e => {
		e.currentTarget.classList.remove('validation-error');
		setName(e.currentTarget.value);
	};

	const isValid = () => {
		const node = document.querySelector('.article-name');
		const name = node.value;
		const result = name.replace(/\s/g, '').length !== 0 && name.length <= 255;
		toogleErrorClass(!result);

		const dateValid = !!startDate;
		setStartDateValid(!!startDate);

		return result && dateValid;
	};

	const toogleErrorClass = status => {
		const node = document.querySelector('.article-name');
		if (status) {
			node.classList.add('validation-error');
		} else {
			node.classList.remove('validation-error');
		}
	};

	const deleteHandler = async () => {
		const id = params.id.split('?')[0];
		const res = await deleteDocument(id);
		if (res.data.responseCode === 0) {
			dispatch(NotificationHelper('Документ удалён', 0));
			navigate(`/knowledge/documents/theme/${themeId}`);
		} else {
			dispatch(NotificationHelper(res.data.errorMessage, 1));
		}
		toggleDeleteModal();
	};

	const toggleDeleteModal = () => {
		setIsDeleteModalOpened(!isDeleteModalOpened);
	};

	const updateHandler = async () => {
		const id = params.id.split('?')[0];
		if (isValid()) {
			if (content) {
				const res = await updateDocument({
					id: id,
					name,
					content,
					themeId: option,
					startDate,
					endDate,
				});
				if (res.data.responseCode === 0) {
					navigate(`/knowledge/documents/view/${themeId}/${id}`);
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			} else {
				dispatch(NotificationHelper('Необходимо указать содержание документа', 1));
			}
		}
	};

	const fetchDocument = useCallback(async () => {
		const id = params.id.split('?')[0];

		const res = await getDocument(id);
		if (res.data.responseCode === 0) {
			let newContent = {
				...res.data.response,
			};

			setCurrentDocument(newContent);
			setName(newContent.wikiName);

			setStartDateDefault(newContent.startDate);
			setEndDateDefault(newContent.endDate);
		} else {
			dispatch(NotificationHelper(res.data.errorMessage, 1));
			navigate(`/knowledge/documents/theme/${themeId}`);
		}
	}, [params.id, dispatch, navigate, themeId]);

	useEffect(() => {
		fetchDocument();
	}, [fetchDocument]);

	const onChangeType = id => {
		setOption(id);
	};

	if (currentDocument) {
		const { wikiName, content } = currentDocument;
		return (
			<div className="Article-create">
				<Notification />

				{isDeleteModalOpened && (
					<DeleteModal
						title="Удаление документа"
						name={wikiName}
						text={`Документ <b>${wikiName}</b> будет удален навсегда. Если Вы уверены, что хотите удалить документ навсегда, введите название <b>${wikiName}</b> в поле ниже.`}
						onAccept={deleteHandler}
						onCancel={toggleDeleteModal}
					/>
				)}

				<Header>
					<Helmet title={`Редактирование документа "${wikiName}"`} />
					<HeaderWrapp>
						<Text type="bold">Редактирование документа</Text>
						<div className="Aheader-block">
							<Button variant={TypeButton.PRIMARY} onClick={updateHandler}>
								Обновить
							</Button>
							<Button variant={TypeButton.SECONDARY} onClick={toggleDeleteModal}>
								Удалить
							</Button>
							<Link to={`/knowledge/documents/view/${themeId}/${id}`}>
								<Button variant={TypeButton.TRETIARY}>Отменить</Button>
							</Link>
						</div>
					</HeaderWrapp>
				</Header>
				<Wrapp>
					<div className="article-form article-form_create">
						<input
							type="text"
							className="article-name"
							onChange={nameHandler}
							placeholder="Заголовок статьи"
							defaultValue={name}
						/>
					</div>

					<div className="article-form-date">
						<FormInputBlock>
							<label className="form-label">Дата начала</label>
							<Datepicker
								error={!startDateValid}
								onChange={data => setStartDate(data)}
								name="startDate"
								placeholder="Выберите дату"
								initialValue={startDateDefault}
							/>
						</FormInputBlock>

						<FormInputBlock>
							<label className="form-label">Дата окончания</label>
							<Datepicker
								onChange={data => setEndDate(data)}
								name="endDate"
								placeholder="Выберите дату"
								initialValue={endDateDefault}
							/>
						</FormInputBlock>
					</div>

					<FormInputBlock>
						<label className="form-label">Тема</label>
						<Select options={options} defaultValue={+themeId} onChange={onChangeType} />
					</FormInputBlock>

					<Editor content={content} getContent={setContent} />
				</Wrapp>
			</div>
		);
	} else {
		return (
			<div className="Article-create">
				<Header>
					<HeaderWrapp></HeaderWrapp>
				</Header>
				<Wrapp>
					<ArticleLoader />
				</Wrapp>
			</div>
		);
	}
};

export default withRouter(DocumentEdit);
