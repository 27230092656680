import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from './mainPage';

import AuthPage from 'pages/auth';
import AuthRegisterPages from 'pages/auth/register';
import AuthRecoverPage from 'pages/auth/recover';
import {
	ArticleGroupCreate,
	ArticleGroupEdit,
	ArticleGroupView,
	GuideArticleCreate,
	GuideArticleView,
	GuideArticleEdit,
} from './new-article/pages';

export default class Router extends Component {
	render() {
		return (
			<Routes>
				<Route path="/login" element={<AuthPage />} />
				<Route path="/register" element={<AuthRegisterPages />} />
				<Route path="/recover" element={<AuthRecoverPage />} />

				<Route path="/group/:id/article/create" element={<ArticleGroupCreate />} />
				<Route path="/group/:id/article/edit/:idArticle" element={<ArticleGroupEdit />} />
				<Route path="/group/:id/article/:idArticle" element={<ArticleGroupView />} />

				<Route
					path="/knowledge/guide/:id/article/create"
					element={<GuideArticleCreate />}
				/>
				<Route
					path="/knowledge/guide/:id/article/:articleId"
					element={<GuideArticleView />}
				/>
				<Route
					path="/knowledge/guide/:id/article/:articleId/edit"
					element={<GuideArticleEdit />}
				/>

				<Route path="/*" element={<MainPage isMain={true} />} />

				{/* <Route element={<MainPage />} /> */}
			</Routes>
		);
	}
}
