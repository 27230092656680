import React from 'react';

export const Heading = () => {
	return (
		<svg
			width="476.8"
			height="512"
			viewBox="0 0 476.8 512"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0 0v83.4h122.2v386.3h108.8v-386.3h123.2v-83.4zM425 469.6v-191.2l-108.3 16.2v38.4h51.8v136.6h-51.6v42.4h159.9v-42.4z" />
		</svg>
	);
};
