import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { api } from 'rest/api';
import { URL_AUTH } from 'constant';
import { setCookie } from 'nookies';
import { getExpiresCookie } from 'plugins/token';

export const useAuth = () => {
	const navigate = useNavigate();

	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const onAuth = useCallback(
		async (username: string, password: string) => {
			setLoading(true);
			setError(false);
			setErrorMessage(null);
			try {
				const response = await api.post(
					'/auth/access',
					{ username, password },
					{
						baseURL: URL_AUTH,
					}
				);
				const { data } = response;
				if (data.responseCode === 0) {
					data.response &&
						setCookie(null, 'accessToken', data.response, {
							expires: getExpiresCookie(),
							path: '/',
						});
					navigate('/');
				} else {
					setError(true);
					setErrorMessage(data.errorMessage);
				}
			} catch (error) {
				setError(true);
				setErrorMessage(`${error}`);
			} finally {
				setLoading(false);
			}
		},
		[navigate]
	);

	return {
		isLoading,
		isError,
		errorMessage,
		onAuth,
	};
};
