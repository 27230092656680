import React, { Fragment, useState, forwardRef } from 'react';
import Textarea from 'react-textarea-autosize';

import './style.scss';

let textId = 0;

export const InputText = forwardRef(function InputText(props, ref) {
	const { id: idProp, label, valid = true, type = 'text', inputMode, ...inputProps } = props;

	const id = idProp ? idProp : `text-input-${++textId}`;

	return (
		<div className={valid ? 'MIInput__text' : 'MIInput__text error'}>
			<input
				{...inputProps}
				id={id}
				type={type}
				autoComplete="off"
				ref={ref}
				inputMode={inputMode}
			/>
			<span className="MIInput__bar"></span>
			<label htmlFor={id}>{label}</label>
		</div>
	);
});

export const InputNumber = forwardRef((props, ref) => {
	const { id, label, onChange, valid = true, value, placeholder, disabled, name } = props;
	return (
		<div className={valid ? 'MIInput__text' : 'MIInput__text error'}>
			<input
				name={name}
				disabled={disabled ? true : false}
				value={value}
				placeholder={placeholder !== undefined ? `${placeholder}` : ''}
				type="number"
				id={id}
				onChange={onChange}
				autoComplete="off"
				min="0"
				inputMode="numeric"
				pattern="[0-9]*"
				ref={ref}
			/>
			<span className="MIInput__bar"></span>
			<label htmlFor={id}>{label}</label>
		</div>
	);
});

export const InputFile = ({ id, label, onChange, loading, value, valid }) => {
	let [fileName, setStateFileName] = useState('');

	const setFileName = name => setStateFileName(name);

	const onChangeLocal = e => {
		let { files } = e.currentTarget;
		if (files[0]) {
			e.currentTarget.classList.add('focus');
			setFileName(files[0].name);
			onChange(e);
		}
	};
	return (
		<Fragment>
			<div className={valid === false ? 'MIInput__file error' : 'MIInput__file'}>
				<label htmlFor={id} className={loading ? 'icon loading' : 'icon'}>
					<svg role="img">
						<use
							xlinkHref={
								process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#attachment'
							}
						></use>
					</svg>
				</label>
				<div className="body">
					<input placeholder="&nbsp;" type="file" id={id} onChange={onChangeLocal} />
					<label htmlFor={id}>{value !== null ? fileName : label}</label>
				</div>
			</div>

			{valid === false && (
				<span className="MIInput__file_error">* Это поле обязательное</span>
			)}
		</Fragment>
	);
};

export const TextArea = ({
	id = '',
	onChange,
	valid = true,
	value,
	placeholder,
	className = '',
	name = '',
}) => {
	return (
		<div className={`${className} ${valid ? 'MITextarea' : 'MITextarea error'}`}>
			<Textarea
				id={id}
				name={name}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
			/>

			<span className="MITextarea__bar"></span>
		</div>
	);
};

export const TextAreaHook = forwardRef(function TextAreaHook(props, ref) {
	const { id, valid = true, placeholder, name, onChange, value } = props;

	return (
		<div className={valid ? 'MITextarea' : 'MITextarea error'}>
			<Textarea
				id={id}
				name={name}
				placeholder={placeholder}
				autoComplete="off"
				inputRef={ref}
				onChange={onChange}
				value={value}
			/>
			<span className="MITextarea__bar"></span>
		</div>
	);
});
