import React from 'react';

import './style.scss';

export const AddFileInput = ({ id, onChange }) => {
	return (
		<div className="MIInput__file">
			<label htmlFor={id} className="icon">
				<svg role="img">
					<use
						xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#attachment'}
					></use>
				</svg>
			</label>
			<div className="body">
				<input type="file" id={id} onChange={onChange} multiple />
				<label htmlFor={id}>Прикрепить документ</label>
			</div>
		</div>
	);
};
