import React from 'react';

export const Quotes = () => {
	return (
		<svg
			width="198"
			height="146.2"
			viewBox="0 0 198 146.2"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0 67h48c-.8 35-10.7 39-23.2 40.1l-4.8.6v38.5l5.5-.3c16.3-.9 34.3-3.9 46.3-18.7 10.6-13 15.2-34.2 15.2-66.8v-60.4h-87v67zM111 0v67h47.4c-.8 35-10.4 39-22.9 40.1l-4.5.6v38.5l5.2-.3c16.3-.9 34.4-3.9 46.5-18.7 10.5-13 15.3-34.2 15.3-66.8v-60.4h-87z" />
		</svg>
	);
};
