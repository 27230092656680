import React from 'react';

export const Ol = () => {
	return (
		<svg
			width="512"
			height="436.6"
			viewBox="0 0 512 436.6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M35.5 254.5l-.2-.9 14.6-15.9c9.5-10.3 16-18.2 19.5-23.5 3.5-5.2 5.3-11.3 5.3-18.1 0-10.1-3.3-18.1-10-24-6.6-5.8-15.9-8.8-27.8-8.8-11.2 0-20.1 3.5-26.9 10.4s-10 15.3-9.8 25.3l.2.3h24.5c0-4.4 1.1-8.9 3.2-12.1 2.2-3.2 5.1-4.7 8.9-4.7 4.2 0 7.4 1.3 9.5 3.7 2.1 2.5 3.2 5.8 3.2 10 0 2.9-1 6.4-3.1 10.3-2.1 4-5.7 8.5-10.1 13.5l-35.2 36.6v15.5h74.8v-17.6h-40.6zM51.9 89.3v-89.3l-50.6 7.6v17.9h24.2v63.8h-24.2v19.9h74.8v-19.9zM73.1 389.3c-3.3-4.3-7.8-7.5-13.6-9.7 5.1-2.3 9.2-5.5 12.2-9.7 3-4.1 4.6-8.6 4.6-13.5 0-9.8-3.5-17.4-10.5-22.8-7-5.4-16.4-8-28.1-8-10.1 0-18.7 2.7-25.7 8-7.1 5.3-10.5 12.7-10.2 21.3l.1.8h24.3c0-4.4 1.2-5.8 3.7-7.7 2.5-1.9 5.3-3 8.6-3 4.2 0 7.3 1.1 9.5 3.4s3.3 5.1 3.3 8.6c0 4.3-1.2 7.9-3.6 10.3-2.4 2.4-5.9 3.8-10.4 3.8h-11.8v17.6h11.7c5 0 8.9 1.5 11.6 3.9 2.7 2.4 4.1 6.4 4.1 11.6 0 3.8-1.3 6.9-3.9 9.4-2.6 2.5-6.1 3.7-10.5 3.7-3.9 0-7.1-1.7-9.8-4-2.7-2.3-4-4.8-4-9.2h-24.5l-.1 1c-.2 10.1 3.4 18 11 23.4 7.6 5.3 16.5 8.1 26.6 8.1 11.8 0 21.4-2.8 29-8.4 7.6-5.7 11.3-13.4 11.3-23.3 0-6.1-1.7-11.3-4.9-15.6zM148.8 324.9h363.2v72.6h-363.2zM148.8 181.8h363.2v72.6h-363.2zM148.8 34.3h363.2v72.6h-363.2z" />
		</svg>
	);
};
