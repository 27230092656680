import React from 'react';
import { URL } from 'constant';

import { MIVideoPlayer } from 'core/video';

import './style.scss';

export const VideoBlock = ({ video }) => {
	const { fileType, processConvert, originalFileName, filePath, preview, id } = video;

	return (
		<div className="question__item-video">
			{fileType === 'FAQ_VIDEO' ? (
				processConvert ? (
					<div className="video__convert">
						<span>{`Видео ${originalFileName} в обработке`}</span>
					</div>
				) : (
					<MIVideoPlayer
						url={`${URL}${filePath}`}
						posterUrl={preview ? `${URL}${preview.filePath}` : ``}
						key={id}
					/>
				)
			) : (
				<div className="faq-video-wrap">
					<iframe
						title="video"
						src={video.originalFileName}
						frameBorder="0"
						allowFullScreen
						className="faq-iframe"
					/>
				</div>
			)}
		</div>
	);
};
