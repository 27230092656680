import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';
import { bodyFixPosition, bodyUnfixPosition } from 'core/utils';
import { groupMenuClose } from 'action/ui';
import { URL } from 'constant';

import * as Icon from 'react-feather';
import { Avatar } from 'core/components';

import './style.scss';

class GroupMenu extends Component {
	componentWillUnmount() {
		bodyUnfixPosition();
	}

	componentDidUpdate(prevProps) {
		const { groupMenuView } = this.props;
		if (prevProps.groupMenuView !== groupMenuView) {
			if (groupMenuView) {
				bodyFixPosition();
			} else {
				bodyUnfixPosition();
			}
		}
	}

	render() {
		const { groupMenuView, location } = this.props;
		const { state } = location;
		return (
			<div
				className={groupMenuView ? 'group-menu__wrapper opened' : 'group-menu__wrapper'}
				onClick={this.groupMenuCloseHandler}
			>
				<div className="group-menu">
					<Link to="/group-create" className="group-menu__item">
						<span className="group-menu__item-create">
							<Icon.Plus />
						</span>
						<span className="group-menu__item-text">
							<b>Создать группу</b>
						</span>
					</Link>

					{this.groupListRenderHelper()}

					<Link
						to={{
							pathname: 'group',
							state: {
								...state,
								back: state && !!state.back ? state.back : -1,
							},
						}}
						className="group-menu__item"
					>
						<span className="group-menu__item-create">
							<Icon.List />
						</span>
						<span className="group-menu__item-text">
							<b>Все группы</b>
						</span>
					</Link>
				</div>
			</div>
		);
	}

	groupListRenderHelper = () => {
		let { groupList } = this.props;
		return groupList.map((item, index) => {
			return (
				<Link to={`/group/${item.id}`} className="group-menu__item" key={index}>
					<Avatar
						type="group"
						size="small"
						image={item.avatarFile !== null ? URL + item.avatarFile.filePath : null}
					/>

					<span className="group-menu__item-text">
						<b>{item.name}</b>
						<i>{item.configClose ? 'Закрытая' : 'Открытая'}</i>
					</span>
				</Link>
			);
		});
	};

	groupMenuCloseHandler = e => {
		e.preventDefault();
		this.props.groupMenuClose();
		document.getElementById('wrapp').classList.remove('no-scroll');
	};
}

const mapStateToProps = state => {
	return {
		groupList: state.groupList.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		groupMenuClose: () => {
			dispatch(groupMenuClose());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
	withRouter(GroupMenu)
);
