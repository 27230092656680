import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as firebase from 'firebase/app';
import 'firebase/messaging';

import { Roles } from 'core/components';
import { hasRole } from 'core/utils';

import { HeaderLink } from '../../atoms';
import { HeaderNav } from '../../molecules';

import { closeHeadMenu } from 'action/ui';

import { deleteToken } from 'rest/push';

import './style.scss';

class HeaderMenu extends Component {
	render() {
		const { ui, shortUserInfo } = this.props;
		const arrayRoles = shortUserInfo.roles;
		if (ui.headerMenu) {
			return (
				<div className="header-menu header-menu_narrow" ref={el => (this.menu = el)}>
					<HeaderNav>
						{arrayRoles ? (
							<Roles isView={hasRole(arrayRoles, 'ROLE_ADMINISTRATOR')}>
								<HeaderLink
									id="admin-user-menu-link"
									url={`/admin`}
									onClick={this.linkOnClick}
								>
									Администратор
								</HeaderLink>
							</Roles>
						) : null}
						<HeaderLink onClick={this.logOutHandler} id="logout-user-menu-button">
							<span className="link-logOut">
								<svg role="img" onClick={this.logOutHandler}>
									<use
										xlinkHref={
											process.env.REACT_APP_PUBLIC_URL +
											'/img/sprite.svg#logout'
										}
									></use>
								</svg>
								Выйти
							</span>
						</HeaderLink>
					</HeaderNav>
				</div>
			);
		} else return null;
	}

	linkOnClick = () => {
		const { closeHeadMenu } = this.props;
		closeHeadMenu();
	};

	logOutHandler = async e => {
		let { logOut, closeHeadMenu } = this.props;
		if ('Notification' in window && Notification.permission === 'granted') {
			await getToken()
				.then(response => {
					if (response.data.responseCode === 0) {
						console.log('Device token is deleted');
						logOut(e);
					} else {
						console.log('Device token is not removed');
					}
					closeHeadMenu();
				})
				.catch(() => {
					closeHeadMenu();
				});
		} else {
			closeHeadMenu();
			logOut(e);
		}
	};

	handleClickOutside = e => {
		const { closeHeadMenu, ui } = this.props;
		const userArrow = document.querySelector('.user-arrow');
		const { target } = e;
		if (
			ui.headerMenu &&
			target !== this.menu &&
			!this.menu.contains(target) &&
			target !== userArrow
		) {
			closeHeadMenu();
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, true);
	}
}

const mapStateToProps = state => {
	return {
		shortUserInfo: state.shortUserInfo,
		ui: state.ui,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		closeHeadMenu: () => {
			dispatch(closeHeadMenu());
		},
	};
};

const getToken = () => {
	return new Promise((resolve, reject) => {
		if (firebase.messaging.isSupported()) {
			const messaging = firebase.messaging();
			if (Notification.permission === 'granted') {
				messaging
					.getToken()
					.then(token => {
						if (token) {
							console.log('Device token received');
							resolve(deleteToken(token));
						} else {
							console.log('Device token not received');
							reject();
						}
					})
					.catch(err => {
						reject();
						console.log('Device token not received, error:', err);
					});
			}
		}
	});
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(HeaderMenu);
