import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AdminAside } from 'modules/admin/adminAside';

import { bodyFixPosition, bodyUnfixPosition } from 'core/utils';

import { closeAdminMenu } from 'action/ui';

import './style.scss';

class MobileAdminMenu extends Component {
	render() {
		let { shortUserInfo, adminMenuView, closeAdminMenu } = this.props;
		let arrayRoles = shortUserInfo.roles;
		return (
			<div className={adminMenuView ? 'mobile-adminMenu opened' : 'mobile-adminMenu'}>
				<div className="mobile-adminMenu__wrapper">
					<AdminAside arrayRoles={arrayRoles} closeAdminMenu={closeAdminMenu} />
				</div>
			</div>
		);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.getElementById('wrapp').classList.add('no-scroll');
		bodyFixPosition();
	}

	componentWillUnmount() {
		document.getElementById('wrapp').classList.remove('no-scroll');
		bodyUnfixPosition();
	}
}

const mapStateToProps = state => {
	return {
		shortUserInfo: state.shortUserInfo,
		adminMenuView: state.ui.adminMenuView,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		closeAdminMenu: () => {
			dispatch(closeAdminMenu());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileAdminMenu);
