import React, { FC, ReactNode } from 'react';

interface IMessageProps {
	closeComponent: ReactNode;
	children: ReactNode;
}

export const Message: FC<IMessageProps> = ({ closeComponent, children }) => {
	return (
		<div className="emilia__message">
			<div className="message__close">{closeComponent}</div>
			<div className="message__content">{children}</div>
		</div>
	);
};
