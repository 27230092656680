import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';
import { textColor } from '../../utils';
import * as Icon from 'react-feather';
import ReactGA from 'react-ga4';

export const ArticleItem = withRouter(
	({ groupId, groupName, id, name, fillText, createDate, indexName, clickHandle }) => {
		const clickArticleItemHandler = () => {
			if (clickHandle) clickHandle();
			ReactGA.event({
				category: 'Переходы по результатам поиска',
				action: 'Переход в "Статьи"',
				label: 'Открытия детального просмотра статей',
			});
		};

		return (
			<Link
				to={`/group/${groupId}/article/${id}?fromIndex=${indexName}`}
				className="block-item block-item_guide"
				onClick={clickArticleItemHandler}
			>
				<svg role="img" className="stroke-svg">
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#search-article'
						}
					/>
				</svg>
				<div className="block-item-info">
					<span>{textColor(name, fillText)}</span>
					<div className="block-item-wrapper">
						<span className="block-item-folder">{textColor(createDate, fillText)}</span>
						<div className="block-item-folder">
							<Icon.List size="18" className="block-item-featherIcon" />
							<span>{textColor(groupName, fillText)}</span>
						</div>
					</div>
				</div>
			</Link>
		);
	}
);
