import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import { NotificationHelper } from 'action/notificationHelper';
import { ViewEditor } from 'components/editorjsText/editorHtml';
import { getDate, getMonth, getYear } from 'date-fns';

import { BreadCrumbs, CrumbItem, Roles } from 'core/components';
import { hasRole } from 'core/utils';

import Notification from 'modules/notification';

import { getDocument } from 'rest/documents';

import { Header } from '../../organisms/header';
import { Wrapp } from '../../atoms/mainWrapp';
import { HeaderWrapp, GoBack, Title } from '../../atoms/header';

import { ArticleLoader } from '../../organisms/loader';
import { useGetIndexName } from 'hooks/useGetIndexName';
import { withRouter } from 'hoc/withRouter';

const DocumentView = ({ params, navigate }) => {
	const fromIndex = useGetIndexName('fromIndex');
	const { id, themeId } = useParams();
	const roles = useSelector(state => state.shortUserInfo?.roles);
	const [document, setDocument] = useState(null);

	const dispatch = useDispatch();

	const fetchDocument = useCallback(async () => {
		const id = params.id.split('?')[0];

		const res = await getDocument(id, fromIndex);
		if (res.data.responseCode === 0) {
			let newContent = {
				...res.data.response,
			};

			setDocument(newContent);
		} else {
			dispatch(NotificationHelper(res.data.errorMessage, 1));
			navigate('/knowledge/documents');
		}
	}, [params.id, dispatch, navigate, fromIndex]);

	useEffect(() => {
		fetchDocument();
	}, [fetchDocument]);

	if (document) {
		const { wikiName, content, theme, startDate } = document;
		const day = getDate(startDate);
		const month = getMonth(startDate);
		const year = getYear(startDate);

		return (
			<div className="Article-view">
				<Helmet title={wikiName} />

				<Notification />

				<Header>
					<HeaderWrapp>
						<GoBack to={`/knowledge/documents`} />
						<Title>{wikiName}</Title>

						<BreadCrumbs>
							<div className="crumb-item">
								<span className="crumb-item__text">Официально</span>
								<svg role="img" className="crumb-item__svg">
									<use
										xlinkHref={
											process.env.REACT_APP_PUBLIC_URL +
											'/img/sprite.svg#bold-mini-arrow'
										}
									></use>
								</svg>
							</div>
							<CrumbItem to={`/knowledge/documents`}>Нормативные документы</CrumbItem>
							<CrumbItem to={`/knowledge/documents/theme/${theme?.id}`}>
								{theme?.title}
							</CrumbItem>
							<CrumbItem>{wikiName}</CrumbItem>
						</BreadCrumbs>
					</HeaderWrapp>
				</Header>
				<Wrapp>
					<div className="view-header">
						<h1>{`${wikiName}`}</h1>

						{roles && (
							<Roles isView={hasRole(roles, 'ROLE_EDIT_WIKI_DOCUMENT')}>
								<Link
									className="article-edit-link"
									to={`/knowledge/documents/edit/${themeId}/${id}`}
								>
									<svg role="img">
										<use
											xlinkHref={`${process.env.REACT_APP_PUBLIC_URL}/img/sprite.svg#pen`}
										/>
									</svg>
									<span>Редактировать</span>
								</Link>
							</Roles>
						)}
					</div>

					<p className="documents-start-date">
						{`${day < 10 ? '0' + day : day}.${
							month < 9 ? '0' + (month + 1) : month + 1
						}.${year}`}
					</p>

					{content && <ViewEditor content={content} />}
				</Wrapp>
			</div>
		);
	} else {
		return (
			<div className="Article-view">
				<Header>
					<HeaderWrapp />
				</Header>
				<Wrapp>
					<ArticleLoader />
				</Wrapp>
			</div>
		);
	}
};

export default withRouter(DocumentView);
