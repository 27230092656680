import React, { FC, useState, useEffect } from 'react';
import { Tooltip, Typography } from '@maxi-innovation/ui-kit-frontend';

import { IQuestionComponentProps, IAnswered } from '../types';
import { URL } from 'constant';
import classNames from 'classnames';
import { useIsMobile } from 'hooks/useIsMobile';

export const ScaleQuestion: FC<IQuestionComponentProps> = ({
	question,
	answeredHandler,
	error,
	defaultValue,
}) => {
	const isMobile = useIsMobile();
	useEffect(() => {
		if (defaultValue?.selectedAnswer) {
			answeredHandler(defaultValue.selectedAnswer);
			setSelectNumber(+defaultValue.selectedAnswer[0]?.text - 1);
		}
	}, [defaultValue, answeredHandler]);

	const [selectNumber, setSelectNumber] = useState<number>();
	const [hoverNumber, setHoverNumber] = useState<number>();

	const changeAnswer = (data: IAnswered, index: number) => {
		answeredHandler([data]);
		setSelectNumber(index);
	};

	return (
		<div className="question">
			{question.image && (
				<img src={URL + question.image.filePath} alt="" className="question__preview" />
			)}

			<Typography as="p" variant="h4" weight="bold" className="question__title">
				{question.text}
			</Typography>

			<div className="question-answer-scale">
				<div className="question-scale-minmax">
					{!isMobile && question.minLabel && (
						<Tooltip text={question.minLabel} width={180}>
							<svg role="img" className="question-scale-minmax__icon">
								<use
									xlinkHref={
										process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#dislike'
									}
								></use>
							</svg>
						</Tooltip>
					)}
				</div>

				<div className="question-answer-scale__wrap">
					{question.possibleAnswers.map((item, index) => (
						<label
							className={classNames('question-answer-scale__item', {
								active: selectNumber !== undefined && selectNumber >= index,
								hover: hoverNumber !== undefined && hoverNumber >= index,
							})}
							key={item.id}
							onMouseEnter={() => setHoverNumber(index)}
							onMouseLeave={() => setHoverNumber(undefined)}
						>
							<input
								type="radio"
								name="question-answer"
								className="question-answer-scale__input"
								onChange={() => changeAnswer(item, index)}
							/>
							<p className="question-answers__text">{item.text}</p>
						</label>
					))}
				</div>

				<div className="question-scale-minmax">
					{!isMobile && question.maxLabel && (
						<Tooltip text={question.maxLabel} width={180}>
							<svg role="img" className="question-scale-minmax__icon">
								<use
									xlinkHref={
										process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#like'
									}
								></use>
							</svg>
						</Tooltip>
					)}
				</div>
			</div>

			{isMobile && question.minLabel && question.maxLabel && (
				<div className="question-minmax-mobile">
					<div className="question-minmax-mobile__item">
						{question.possibleAnswers.at(0)?.text} - {question.minLabel}
					</div>
					<div className="question-minmax-mobile__item max">
						{question.possibleAnswers.at(-1)?.text} - {question.maxLabel}
					</div>
				</div>
			)}

			{error && <div className="question-answers-error">Выберите оценку</div>}
		</div>
	);
};
