import { withRouter } from 'hoc/withRouter';
import React, { Component } from 'react';

class ArrowGoBack extends Component {
	render() {
		const { children } = this.props;
		return (
			<div className="header-title">
				<svg role="img" className="icon icon_back" onClick={this.clickHandle}>
					<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#arrow'} />
				</svg>
				{children}
			</div>
		);
	}

	clickHandle = () => {
		const { navigate } = this.props;
		navigate(-1);
	};
}

export default withRouter(ArrowGoBack);
