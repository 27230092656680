import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ValidationSchema } from '../../model/validationSchema';
import { IPulseSurveyData, IPulseSurveyDataForRHF } from 'entities/survey/pulseSurveyTypes';
import {
	clearPulseSurveyAction,
	setPulseSurveyAction,
	updatePulseSurveyAnonymousAction,
} from 'action/PulseSurvey';
import { createPulseSurveyRest, editPulseSurveyRest, getPulseSurveyRest } from 'shared/api/survey';
import { NotificationHelper } from 'action/notificationHelper';
import {
	AnswerType,
	DateFields,
	PulseSurveyTextField,
	PulseSurveyView,
	SwitchField,
} from './fields';
import { Button, TypeButton } from '@maxi-innovation/ui-kit-frontend';

import './style.scss';

interface ISelector {
	pulseSurvey: IPulseSurveyData;
}

interface IParams {
	id: string;
}

export const PulseSurveyForm = () => {
	const { id } = useParams<keyof IParams>() as IParams;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pulseSurvey } = useSelector((state: ISelector) => ({
		pulseSurvey: state.pulseSurvey,
	}));

	const [isLoading, setLoading] = useState(false);
	const [fixUsers, setFixUsers] = useState(0);

	const methods = useForm<IPulseSurveyDataForRHF>({
		reValidateMode: 'onSubmit',
		validationSchema: ValidationSchema,
	});
	const { handleSubmit } = methods;

	useEffect(() => {
		return () => {
			dispatch(clearPulseSurveyAction());
		};
	}, [dispatch]);

	useEffect(() => {
		(async () => {
			if (!id) return;

			const { response, responseCode, errorMessage } = await getPulseSurveyRest(id);

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				dispatch(setPulseSurveyAction(response));
			}
		})();
	}, [id, dispatch]);

	const onSubmit = handleSubmit(async data => {
		setLoading(true);
		const { responseCode, errorMessage } = id
			? await editPulseSurveyRest({ id, ...data })
			: await createPulseSurveyRest(data);

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, responseCode));
			setLoading(false);
		} else {
			navigate(`/services/pulse-survey/list`);
		}
	});

	return (
		<FormContext {...methods}>
			<form className="pulse-survey-form" onSubmit={onSubmit}>
				<div className="pulse-survey-form__wrap">
					<DateFields startDate={pulseSurvey.startDate} endDate={pulseSurvey.endDate} />

					<p className="create-survey-form__part-title">Участники пульс-опроса</p>

					<PulseSurveyView
						key={fixUsers}
						fixViewComponent={() => setFixUsers(prev => prev + 1)}
						forUsers={pulseSurvey.forUsers}
						forPositions={pulseSurvey.forPositions}
						forDepartments={pulseSurvey.forDepartments}
					/>

					<SwitchField
						defaultValue={pulseSurvey.anonymous}
						text="Анонимный опрос"
						name="anonymous"
						id="pulse-survey_form_anonymous"
						change={(anonymous: boolean) =>
							dispatch(updatePulseSurveyAnonymousAction(anonymous))
						}
					/>
				</div>

				<div className="pulse-survey-form__wrap">
					<PulseSurveyTextField question={pulseSurvey.question} />

					<AnswerType answerScaleType={pulseSurvey.answerScaleType} />
				</div>

				<div className="pulse-survey-form__wrap">
					<Button
						variant={TypeButton.PRIMARY}
						type="submit"
						className="pulse-survey-form__submit"
						id="pulse-survey_form_submit"
						disabled={isLoading}
						loading={isLoading}
					>
						Сохранить
					</Button>
				</div>
			</form>
		</FormContext>
	);
};
