import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'hoc/withRouter';

import './style.scss';

export const GoBack = withRouter(({ to, location, navigate }) => {
	const { state } = location;

	return to && !(state && typeof state.back !== undefined) ? (
		<Link className="Aheader-goback" to={to}>
			<svg role="img">
				<use xlinkHref={`${process.env.REACT_APP_PUBLIC_URL}/img/sprite.svg#arrow`} />
			</svg>
			Назад
		</Link>
	) : (
		<button className="Aheader-goback" type="button" onClick={() => navigate(state.back)}>
			<svg role="img">
				<use xlinkHref={`${process.env.REACT_APP_PUBLIC_URL}/img/sprite.svg#arrow`} />
			</svg>
			Назад
		</button>
	);
});
