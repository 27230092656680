import React from 'react';

import { URL } from 'constant';

import './style.scss';

export const UserImage = ({ url }) => {
	if (url === null) {
		return (
			<div className="user-image">
				<svg role="img">
					<use
						xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#no-avatar'}
					/>
				</svg>
			</div>
		);
	} else {
		return (
			<div className="user-image">
				<img src={`${URL}${url.filePath}`} alt="" />
			</div>
		);
	}
};
