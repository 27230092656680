import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

export const ActionLink = ({ to }) => {
	return (
		<Link to={to} className="actionLink">
			<svg role="img" className="actionLink__icon">
				<use xlinkHref={`${process.env.REACT_APP_PUBLIC_URL}/img/sprite.svg#plus`} />
			</svg>
		</Link>
	);
};
