import React from 'react';

export const Camera = () => {
	return (
		<svg
			width="548.2"
			height="475.1"
			viewBox="0 0 548.2 475.1"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M526.8 94.5c-14.3-14.3-31.5-21.4-51.7-21.4h-64l-14.6-38.8c-3.6-9.3-10.2-17.4-19.8-24.1-9.6-6.8-19.4-10.2-29.5-10.2h-146.2c-10.1 0-19.9 3.4-29.6 10.1-9.6 6.8-16.2 14.8-19.8 24.1l-14.6 38.9h-64c-20.2 0-37.4 7.1-51.7 21.4-14.2 14.3-21.3 31.5-21.3 51.7v255.8c0 20.2 7.1 37.4 21.4 51.7 14.3 14.3 31.5 21.4 51.7 21.4h402c20.2 0 37.4-7.1 51.7-21.4 14.3-14.3 21.4-31.5 21.4-51.7v-255.8c0-20.2-7.2-37.4-21.4-51.7zm-162.4 270c-25 25-55.1 37.5-90.4 37.5s-65.3-12.5-90.4-37.5c-25-25-37.5-55.2-37.5-90.4s12.5-65.3 37.5-90.4c25-25 55.1-37.5 90.4-37.5s65.3 12.5 90.4 37.5c25 25 37.5 55.1 37.5 90.4.1 35.2-12.4 65.3-37.5 90.4zM274.1 191.9c-22.7 0-42 8-58.1 24.1s-24.1 35.4-24.1 58.1c0 22.6 8 42 24.1 58.1s35.4 24.1 58.1 24.1c22.6 0 42-8 58.1-24.1s24.1-35.5 24.1-58.1c0-22.7-8-42-24.1-58.1s-35.5-24.1-58.1-24.1z" />
		</svg>
	);
};
