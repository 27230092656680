import {
	GET_VACANCIES_LIST,
	VACANCY_INFO,
	VACANCIES_LIST_PARAMS,
	VACANCIES_LIST_PARAMS_UPDATE,
	VACANCIES_CITIES_LIST,
} from '../constant';

import { NotificationHelper } from './notificationHelper';

import {
	createVacancy,
	getVacancyInfo,
	getVacanciesList,
	getVacanciesCitiesList,
} from '../rest/vacancies';

export const setCreateVacancy = (data, navigate) => {
	return dispatch => {
		return createVacancy(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Вакансия добавлена', 0));
					navigate(`/vacancies/${+res.data.response.id}`);
				} else {
					dispatch(NotificationHelper('Ошибка добавления вакансии', 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка добавления вакансии', 1));
			});
	};
};

export const setVacanciesList = list => {
	return dispatch => {
		return getVacanciesList(list)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_VACANCIES_LIST,
						vacancies: res.data.response,
					});
					dispatch({
						type: VACANCIES_LIST_PARAMS,
						params: list,
					});
				} else {
					dispatch(NotificationHelper('Ошибка получения списка вакансий', 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка вакансий', 1));
			});
	};
};

export const setVacancyItem = id => {
	return dispatch => {
		return getVacancyInfo(id)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: VACANCY_INFO,
						vacancy: res.data.response,
					});
				} else {
					dispatch(NotificationHelper('Ошибка получения информации о вакансии', 2));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения информации о вакансии', 2));
			});
	};
};

export const setVacanciesCitiesList = () => {
	return dispatch => {
		return getVacanciesCitiesList()
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: VACANCIES_CITIES_LIST,
						citiesList: res.data.response,
					});
				} else {
					dispatch(NotificationHelper('Ошибка получения списка городов', 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка городов', 1));
			});
	};
};

export const updateFilterParams = params => {
	return {
		type: VACANCIES_LIST_PARAMS_UPDATE,
		params: params,
	};
};
