import classNames from 'classnames';
import React, { Component } from 'react';

import './style.scss';

class Select extends Component {
	state = {
		label: '',
		value: '',
		isView: false,
	};

	render() {
		let { label, isView } = this.state;
		let { options, placeholder, defaultValue, defaultLabel, disabled, id, error } = this.props;
		return (
			<div className="MISelect" ref={el => (this.select = el)}>
				<div className={this.classNameViewHelper(isView)}>
					<span className="MISelect__label">{label || defaultLabel}</span>
					<span className="MISelect__placeholder">{placeholder}</span>
					<svg role="img" className="MISelect__checkmark">
						<use
							xlinkHref={
								process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#mini-arrow'
							}
						></use>
					</svg>
				</div>
				<div className={this.classNameOptionsHelper(isView)}>
					{options.map((option, index) => (
						<span
							id={`${id}--${option.value}`}
							key={index}
							className={classNames('MISelect__option', {
								active: option.value === defaultValue,
								error,
							})}
							onClick={
								!disabled
									? () => this.onChangeOptionHandler(option)
									: e => {
											e.preventDefault();
									  }
							}
						>
							{option.label}
						</span>
					))}
				</div>
			</div>
		);
	}

	onChangeOptionHandler = ({ label, value, data = undefined }) => {
		let { onChange } = this.props;
		onChange(value, label, data);
		this.setState({
			label,
			value,
			isView: false,
		});
	};

	handleClick = e => {
		let { target } = e;
		if (!this.props.disabled) {
			if (target === this.select || this.select.contains(target)) {
				this.setState({
					isView: !this.state.isView,
				});
			} else {
				this.setState({
					isView: false,
				});
			}
		}
	};

	classNameViewHelper(isView) {
		const { error } = this.props;
		let classStr = '';

		if (this.props.disabled) {
			classStr = 'MISelect__view MISelect__view_disabled';
		} else if (isView) {
			classStr = 'MISelect__view view';
		} else classStr = 'MISelect__view';

		if (error) classStr = classStr + ' error';

		return classStr;
	}

	classNameOptionsHelper(isView) {
		if (isView) {
			return 'MISelect__options view';
		} else return 'MISelect__options';
	}

	componentDidMount() {
		this.updateState();
		window.addEventListener('click', this.handleClick, true);
	}

	updateState = () => {
		let { options, defaultValue } = this.props;
		for (let option of options) {
			if (option.value === defaultValue) {
				this.setState({
					label: option.label,
					value: option.value,
				});
			}
		}
	};

	componentWillUnmount() {
		window.removeEventListener('click', this.handleClick, true);
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.options.length !== this.props.options.length ||
			prevProps.defaultValue !== this.props.defaultValue
		) {
			this.updateState();
		}
	}
}

export default Select;
