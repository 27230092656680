import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RefreshCcw } from 'react-feather';

import { URL } from 'constant';
import { fileSave } from 'shared/api/survey';
import { updateSurveyPreviewAction } from 'action/survey';
import { NotificationHelper } from 'action/notificationHelper';
import { ISurvey } from '../types';

interface ISelector {
	survey: ISurvey;
}

export const SurveyPreview: FC = () => {
	const dispatch = useDispatch();
	const { cover } = useSelector((state: ISelector) => ({
		cover: state.survey.cover,
	}));
	const [preview, setPreview] = useState<string | undefined>();

	useEffect(() => {
		if (!cover) return;

		setPreview(URL + cover.filePath);
	}, [cover]);

	const uploadImage = (event: any) => {
		let file = event.target.files[0];
		let reader = new FileReader();
		reader.onload = async () => {
			if (!reader.result) return;
			const uploadImg = reader.result.slice((reader.result as string).indexOf(',') + 1);

			const { response, responseCode, errorMessage } = await fileSave({
				originalFileName: file.name,
				base64File: uploadImg,
				fileType: 'SURVEY_COVER',
			});

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				dispatch(updateSurveyPreviewAction(response));

				setPreview('data:image/png;base64,' + uploadImg);
			}
		};
		reader.readAsDataURL(file);
	};

	return (
		<div className="survey-preview">
			<input
				id="survey-preview"
				type="file"
				className="survey-preview__input"
				onChange={uploadImage}
			/>

			<svg role="img" className="survey-preview__icon">
				<use xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#picture'}></use>
			</svg>

			{preview && (
				<>
					<img alt="survey-cover" src={preview} className="survey-preview__img" />

					<label htmlFor="survey-preview" className="survey-preview-refresh">
						<RefreshCcw className="survey-preview-refresh__icon" />
						<span className="survey-preview-refresh__text">Обновить обложку</span>
					</label>
				</>
			)}

			<p className="survey-preview__text">
				<label htmlFor="survey-preview" className="survey-preview__label">
					Загрузите файл
				</label>{' '}
				или перетащите его в эту область <br /> Размер изображения 1830 x 445px, формат PNG,
				JPG до 5MB
			</p>
		</div>
	);
};
